import React from 'react'
import { Button, Checkbox, Col,Tag, DatePicker, Divider, Form, Input, Radio, Row, Select, Table, message, Modal, Icon, Upload, InputNumber, Tooltip, Popconfirm } from 'antd'
import { compose, graphql, withApollo } from 'react-apollo';
import moment from 'moment'
import { PhotoshopPicker } from 'react-color'
import uuidv1 from 'uuid/v1';
import App from '../../App'
import ReactColor from '../../funComponent/reactColor'
import CreateSiteM from '../../mutation/createSite'
import createServiceType from '../../mutation/createServiceType'
import verifyUsername from '../../mutation/verifyUsername'
import SitePages from '../../mutation/addPagesToSite'
import getSitePagesById from '../../queries/getStaticPages';
import domainAvailability from '../../queries/domainAvailability';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Highlighter from 'react-highlight-words';
import getSitePagesByAdmin from '../../queries/getSitePagesByAdmin'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import createSiteConfig from '../../mutation/createSiteConfig'
import updateSiteRewardSetting from '../../mutation/updateSiteRewardSettingV2Alias'
import getInternalUsers from '../../queries/getInternalUsers'
import {getStateByCountry,getcitiesByStateAndCountry} from "../../utils/getStateByCountry"

import OneTimeService from './oneTimeService'
import NewSiteSP from './newSiteSP'
const country = [
    { value: 'IND', label: 'India',key:'IN' },
    { value: 'AUS', label: 'Australia',key:'AU' },
    { value: 'USA', label: 'USA',key: 'US' },
    { value: 'UK', label: 'UK',key:'GB' },
    { value: 'UAE', label: 'UAE',key:'AE' },
    { value: 'ZMW', label: 'Zambia',key:'ZM' },
    { value: 'SAU', label: 'Saudi Arabia',key:'SA' },
    { value: 'QAT', label: 'Qatar',key:'QA' },
    { value: 'BHR', label: 'Bahrain',key:'BH' },
    { value: 'SGP', label: 'Singapore',key:'SG' },
    { value: 'NPL', label: 'Nepal',key:'NP' },
    { value: 'KWT', label: 'Kuwait',key:'KW' },
    { value: 'OMN', label: 'Oman',key:'OM' },
    { value: 'KEN', label: 'Kenya',key:'KE' },
    { value: 'MDV', label: 'Maldives',key:'MV' },
    { value: 'IDN', label: 'Indonesia',key:'ID' },
    { value: 'THA', label: 'Thailand',key:'TH' },
    { value: 'MDV', label: 'Malaysia',key:'MY' },
  ]
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const fontType = [
    { value: 'Roboto' },
    { value: 'Lato' },
    { value: 'Open Sans' },
    { value: 'Solway' },
    { value: 'Gupter' },
    { value: 'Montserrat' },
    { value: 'Roboto Condensed' },
    { value: 'Alatsi' },
    { value: 'Source Sans Pro' },
    { value: 'Oswald' },
    { value: 'Raleway' },
    { value: 'Baskervville' },
    { value: 'Roboto Mono' },
    { value: 'Merriweather' },
    { value: 'Roboto Slab' },
    { value: 'Sulphur Point' },
    { value: 'PT Sans' },
    { value: 'Ubuntu' },
    { value: 'Playfair Display' },
    { value: 'Hind' },
    { value: 'Muli' },
    { value: 'Lora' },
    { value: 'Work Sans' },
    { value: 'Fira Sans' },
    { value: 'Josefin Sans' },
    { value: 'Cantata One' },
    { value: 'Cardo' },
    { value: 'Poppins' },
    { value: 'Domine' },
    { value: 'Karla' },
    { value: 'Cabin' },
    { value: 'Heebo' },
    { value: 'Barlow' },
    { value: 'Ropa Sans' },
    { value: 'Squada One' },
    { value: 'Mountains of Christmas' },
    { value: 'Lobster' },
    { value: 'Abril Fatface' },
    { value: 'Cutive Mono' },
    { value: 'Bitter' },
    { value: 'Arvo' },
    { value: 'Anton' },
    { value: 'Notable' },
    { value: 'Fjalla One' },
    { value: 'Mukta' },
    { value: 'Varela Round' },
    { value: 'Darker Grotesque' },
    { value: 'Overpass' },
    { value: 'Istok Web' },
    { value: 'Sansita One' },
    { value: 'Slabo' },
    { value: 'Spectral' },
    { value: 'Source Sans' },
    { value: 'Abhaya Libre' },
    { value: 'Alegreya' },
    { value: 'Aleo' },
    { value: 'Arapey' },
    { value: 'Nunito' },
    { value: 'Asap Condensed' },
    { value: 'Assistant' },
    { value: 'Brawler' },
    { value: 'Caladea' },
    { value: 'ROKKITT' },
    { value: 'Carme' },
    { value: 'Rubik' },
    { value: 'Enriqueta' },
    { value: 'Gelasio' },
    { value: 'Amiri' },
    { value: 'Zilla Slab' },
    { value: 'Josefin Slab' },
    { value: 'Unna' },
    { value: 'Indie Flower' },
    { value: 'Bonbon' },
    { value: 'Space Mono' },
    { value: 'Anonymous Pro' },
    { value: 'Oxygen Mono' },
    { value: 'PT Mono' },
    { value: 'Cousine' },
    { value: 'Original Surfer' },
].sort((a,b)=>a.value.localeCompare(b.value))

fontType.sort((a, b) => { return a.value > b.value ? 1 : -1 })
const type = [
    { key: '1', value: 'SIMPLE_B2C', label: 'e-Commerce' },
    { key: '2', value: 'MARKETING', label: 'Marketing Site' },
    // { key: '4', value: 'JEWEL_EXPO_BASIC', label: 'Jewel Expo Basic' },
    // { key: '5', value: 'JEWEL_EXPO_PREMIUM', label: 'Jewel Expo Premium' },
    // { key: '6', value: 'B2B_ECOM', label: 'B2B e-Commerce' },
    { key: '7', value: 'B2C_MARKET_PLACE', label: 'B2C Market Place' },
    // { key: '8', value: 'B2B2C_ECOM', label: 'B2B2C Ecommerce' },
    { key: '9', value: 'O2OCommerce', label: 'O2O Commerce' }
]
const currency = [
    { value: 'INR', label: 'INR' },
    { value: 'AUD', label: 'AUD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'USD', label: 'USD' },
    { value: 'AED', label: 'AED' },
    { value: 'ZMW', label: 'ZMW' },
    { value: 'SAR', label: 'SAR' },
    { value: 'QAR', label: 'QAR' },
    { value: 'BHD', label: 'BHD' },
    { value: 'SGD', label: 'SGD' },
    { value: 'NPR', label: 'NPR' },
    { value: 'KWD', label: 'KWD' },
    { value: 'OMR', label: 'OMR' },
    { value: 'KES', label: 'KES' },
    { value: 'MVR', label: 'MVR' },
    { value: 'IDR', label: 'IDR' },
    { value: 'THB', label: 'THB' },
    { value: 'MYR', label: 'MYR' },
]

const siteOptions = [
    { value: 'COMMERCE', label: 'e-Commerce' },
    // { value: 'ENQUIRY', label: 'Enquiry' },
    { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
    // { value: 'BOOKING_SALOON', label: 'Saloon' },
    // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
    // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
    // { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
    // { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
    // { value: 'LAUNDRY', label: 'Laundry' },
    // { value: 'EVENT', label: 'Event' },
    // { value: 'DOCTOR', label: 'Doctor' },
    { value: 'GRANITE_BUSINESS', label: 'Granite Business' }
]

var PLATFORM = [
                // { value: 'ENQUIRY', label: 'Enquiry' },
                { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
                { value: 'BOOKING_SALOON', label: 'Saloon' },
                // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
                // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
                { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
                { value: 'LAUNDRY', label: 'Laundry' },
                { value: 'EVENT', label: 'Event' },
                { value: 'VILLA_RENTAL', label: 'Vill Rental' },
                { value: 'SERVICES', label: 'Services' },
                { value: 'CAR_RENTAL', label: 'Car Rental' },
                { value: 'CONSTRUCTION', label: 'Construction' },
                { value: 'GROCERY', label: 'Grocery' },
                { value: 'GENERAL', label: 'General' },
                { value: 'LAWYER', label: 'Lawyer' },
                { value: 'PET_GROOMERS', label: 'Pet Groomers' },
                { value: 'REPAIR_SERVICES', label: 'Repair Services' },
                { value: 'SPA', label: 'Spa' },
                { value: 'GYM', label: 'Gym' },
                { value: 'MANUFACTURER', label: 'Manufacturer' },
                { value: 'JEWELLERY', label: 'Jewellery' },
                { value: 'MARRIAGE', label: 'Marriage' },
                { value: 'AUTO_SERVICES', label: 'Auto Services' },
                { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
                { value: 'DOCTOR', label: 'Doctor' },
                { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
                { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
                { value: 'WAREHOUSE', label: 'Warehouse' },
].sort((a,b)=>a.label.localeCompare(b.label))

const payOptions = [
    // { label: 'No', value: 'NO' },
    { label: 'COD', value: 'COD' },
    { label: 'Razor Pay', value: 'RAZORPAY' },
    { label: 'Paypal', value: 'PAYPAL' },
    { label: 'Payment Button', value: 'PAYMENT_BTN' },
];
let id = 1;
let dispVal = []
const { RangePicker } = DatePicker;
const bucket = process.env.REACT_APP_BUCKET
const s3_Region = process.env.REACT_APP_S3_REGION

const paymentModes = [
    {
        internalName: "CASH",
        disabled: false,
        // displayName: "CASH"
    },
    {
        internalName: "CREDIT_CARD",
        disabled: false,
        // displayName: "CREDIT CARD",
    },
    {
        internalName: "DEBIT_CARD",
        disabled: false,
        // displayName: "DEBIT CARD",
    },
    {
        internalName: "NETBANKING",
        disabled: false,
        // displayName: "NETBANKING"
    },
    {
        internalName: "RAZORPAY",
        disabled: false,
        // displayName: "RAZORPAY"
    },
    {
        internalName: "CCAVENUE",
        disabled: false,
        // displayName: "CHEQUE"
    },
    {
        internalName: "PAYPAL",
        disabled: false,
        // displayName: "PAYPAL"
    },
    {
        internalName: "UPI",
        disabled: false,
        // displayName: "UPI"
    },
    {
        internalName: "PAYMENT_BTN",
        disabled: false,
        // displayName: "PAYMENT BUTTON",
    },
    {
        internalName: "AGGREGATOR_PAYMENT",
        disabled: false,
        // displayName: "AGGREGATOR PAYMENT",
    },
    {
        internalName: "MOBILE_MONEY",
        disabled: false,
        // displayName: "MOBILE MONEY",
    },
    {
        internalName: "VOUCHER",
        disabled: false,
        // displayName: "VOUCHER"
    },
    {
        internalName: "SWISH",
        disabled: false,
        // displayName: "SWISH"
    },
    {
        internalName: "WALLET",
        disabled: false,
        // displayName: "WALLET"
    },
    {
        internalName: "BANK_TRANSER",
        disabled: false,
        // displayName: "BANK TRANSER"
    },
    {
        internalName: "CHEQUE",
        disabled: false,
        // displayName: "CHEQUE"
    },
    {
        internalName: "CARD",
        disabled: false,
        // displayName: "CHEQUE"
    },
    ]

let children = []    
class CreateSite extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            walletSetting: false,
            // siteResp: {
            //     siteId: "sldbnj",
            //     siteType: 'O2OCommerce',
            //     siteOption: 'BOOKING_RESTAURANTS',
            //     fqdn: 'sdgv.fg.sd'
            // },
            siteResp: {},
            siteOptionVal: "COMMERCE",
            paymentOption: ["COD"],
            bg: '#FFFFFF',
            showPicker: false,
            textColor: "#000000",
            labelColor: '#ea2b1f',
            menuColor: '#800000',
            fontStyle: "Roboto Condensed",
            showText: false,
            loading: false,
            siteMode: 'LIVE',
            wallet: true,
            serviceData:[],
            defaultService: [],
            specTypeDisplay: [],
            specStatus:[],
            selectedCharges: [],
            whatsappToSeller: true,
            whatsappToBuyer: true,
            emailToBuyer: true,
            feedBackToBuyer: false,
            inventory: false,
            isGroupAdmin: false,
            frontSite: true,
            multipleWhatsapp: false,
            photo: false,
            displayPLP: false,
            siteType: '',
            validFrom: '',
            userNameCheck: true,
            showError:false,
            channelPartnerData:null,
            validTo: '',
            data: [],
            pages: [],
            previewVisible: false,
            siteLogo: {},
            noProduct: false,
            shipping: false,
            courierOption: "SELF",
            selectedRowKeys: [],
            siteOptions1: '',
            searched:false,
            groupId: undefined,
            partnerType: undefined,
            data11:[],
            userData: [],
            selectedCountry: undefined,
            selectedState: undefined,
            selectedCity: undefined,
            allState:[],
            allCities:[],
            tags:[
                { text: 'Contacts', value: 'CONTACTS' },
                { text: 'Contents', value: 'CONTENTS' },
                { text: 'Custom', value: 'CUSTOM' },
                { text: 'Features', value: 'FEATURES' },
                { text: 'Footers', value: 'FOOTERS' },
                { text: 'Form', value: 'FORM' },
                { text: 'Headers', value: 'HEADERS' },
                { text: 'Pricing', value: 'PRICING' },
                { text: 'Teams', value: 'TEAMS', },
                { text: 'Testimonials', value: 'TESTIMONIALS' },
                { text: 'Call to Action', value: 'CallToAction' },
                { value: 'COVERS', text: 'Covers' },
                { value: 'BLOG', text: 'Blog' },
                { value: 'ECOMMERCE', text: 'E-commerce' },
                { value: 'MAGAZINE', text: 'Magazine' },
                { value: 'PROJECTS', text: 'Projects' },
                { value: 'COUNTERS', text: 'Counters' },
                { value: 'DOWNLOAD', text: 'Download' },
                { value: 'EXPLORE', text: 'Explore' },
                { value:'FAQ', text:'FAQ'},
                { value:'GALLERY', text:'Gallery'},
                { value:'MAPS', text:'Maps'},
                { value:'CUSTOMERS', text:'Customers'},
                { value:'PORTFOLIO', text:'Portfolio'},
                { value:'STEPS', text:'Steps'},
                { value:'SERVICE', text:'Service'},
                { value:'SIGNUP', text:'Sign up'},
                { value:'SUBSCRIBE', text:'Subscribe'},
                { value:'VIDEO', text:'Video'},
                { value:'ADMIN', text:'Admin'},
                { value:'INDUSTRY', text:'Industry'},
                { value:'POLICIES', text:'Policies'},
                { value:'SINGLE_PAGE_SITE', text:'Single Page Site'},
                { value:'LANDING_PAGE', text:'Landing Page'}



            ],
            paymentModes: [],
            displayName: "",
            internalName: "",
            paymentState: "",
            paymentModesAvailable: [
                {
                    internalName: "CASH",
                    disabled: false,
                    paymentState: "PAID_OFFLINE",
                    // displayName: "CASH"
                },
                {
                    internalName: "CREDIT_CARD",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "CREDIT CARD",
                },
                {
                    internalName: "DEBIT_CARD",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "DEBIT CARD",
                },
                {
                    internalName: "NETBANKING",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "NETBANKING"
                },
                {
                    internalName: "RAZORPAY",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "RAZORPAY"
                },
                {
                    internalName: "CCAVENUE",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "RAZORPAY"
                },
                {
                    internalName: "PAYTM_EDC",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "RAZORPAY"
                },
                {
                    internalName: "PAYTM",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "RAZORPAY"
                },
                {
                    internalName: "PAYPAL",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "PAYPAL"
                },
                {
                    internalName: "UPI",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "UPI"
                },
                {
                    internalName: "PAYMENT_BTN",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "PAYMENT BUTTON",
                },
                {
                    internalName: "AGGREGATOR_PAYMENT",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "AGGREGATOR PAYMENT",
                },
                {
                    internalName: "MOBILE_MONEY",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "MOBILE MONEY",
                },
                {
                    internalName: "VOUCHER",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "VOUCHER"
                },
                {
                    internalName: "SWISH",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "SWISH"
                },
                {
                    internalName: "WALLET",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "WALLET"
                },
                {
                    internalName: "BANK_TRANSFER",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "BANK TRANSER"
                },
                {
                    internalName: "CHEQUE",
                    disabled: false,
                    paymentState: "PAID_OFFLINE",
                    // displayName: "CHEQUE"
                },
                {
                    internalName: "CARD",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "CHEQUE"
                },
                {
                    internalName: "WRITE_OFF",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                    // displayName: "CHEQUE"
                },
                {
                    internalName: "DINEOUT",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "EAZYDINER",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "SWIGGY_PAY",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "ZOMATO_PAY",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "BENEFIT",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "BRFC_PAYMENT",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "BRFC_DEBIT",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "AMEX_CARD",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
                {
                    internalName: "CASH_CARD",
                    disabled: false,
                    paymentState: "PAID_ONLINE",
                },
            ],
        }
    }

    runQuery = (nextToken, limit) => {
        // console.log(nextToken, limit)
        this.props.client.query({
          query: getSitePagesByAdmin,
          fetchPolicy: 'network-only',
          variables: {
            nextToken: nextToken,
            limit: limit
          }
        })
          .then(({ data }) => {
            if (data && data.getSitePagesByAdmin) {
              // console.log("data...getSitePagesByAdmin", data)
              let data1 = this.state.data.concat(data.getSitePagesByAdmin.items)
              // console.log("data...full", data1)
              let nextToken1 = data.getSitePagesByAdmin.nextToken
              this.setState({
                data: data1
              }, () => {
                if (nextToken1 != null && nextToken1 != undefined && nextToken1 != '') {
                  this.runQuery(nextToken1, 70)
                }
              })
            }
    
          })
          .catch(err => {
            console.log(`Error : ${JSON.stringify(err)}`)
    
          })
      }

    componentDidMount(){
        // this.getServiceCharges()
      this.runQuery('',70)
      this.getUsers()
        // this.domainCheck()
      
        let arr = this.state.tags
        arr.sort(function (a, b) {
            var nameA = a.text.toLowerCase(), nameB = b.text.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
        this.setState({
            tags: arr
        })
    }
    domainCheck=(domain)=>{
        console.log("domainCheck ::",domain)
        return new Promise((resolve,reject)=>{
        this.props.client.query({
            query: domainAvailability,
            fetchPolicy: 'network-only',
            variables: {
                domain: domain,
            }
          })
            .then(({ data }) => {
                console.log("domainAvailability ::",data)
                resolve(data.domainAvailability)
            })
        })
    }
    getUsers=()=>{
        this.props.client.query({
            query: getInternalUsers,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
                this.setState({
                    userData: data.getInternalUsers,
                    loading: false
                })
            })
    }
    // static getDerivedStateFromProps(nextProps) {
    //     if (nextProps.data.getSitePagesBySiteId) {

    //         return {
    //             data: nextProps.data.getSitePagesBySiteId.items,
    //         }
    //     }
    //     return null
    // }


    onChange = (e) => {
        this.setState({
            siteOptionVal: e.target.value,
        });
    }
    onChange1 = (value) => {
        console.log('changed', value);
    }

    enterLoading = () => {
        this.setState({ loading: true })
    }

    onChangePayment = (val) => {
        this.setState({
            paymentOption: val,
        });
    }
    siteMode = (e) => {
        this.setState({
            siteMode: e.target.value,
        });
    }
    whatsappToSeller = (e) => {
        this.setState({
            whatsappToSeller: e.target.value,
        });
    }
    whatsappToBuyer = (e) => {
        this.setState({
            whatsappToBuyer: e.target.value,
        });
    }
    emailToBuyer = (e) => {
        this.setState({
            emailToBuyer: e.target.value,
        });
    }
    feedBackToBuyer = (e) => {
        this.setState({
            feedBackToBuyer: e.target.value,
        });
    }
    inventory = (e) => {
        this.setState({
            inventory: e.target.value,
        });
    }
    frontSite = (e) => {
        this.setState({
            frontSite: e.target.value,
        });
    }
    isGroupAdmin = (e) => {
        this.setState({
            isGroupAdmin: e.target.value,
            groupId: e.target.value ? this.state.groupId : null
        });
    }
    referenceSite = (value) => {
        this.setState({
            groupId: value,
        });
    }
    walletchange = (e) => {
        this.setState({
            wallet: e.target.value,
        }
        // ,()=>{
        //     this.getServiceCharges()
        // }
        );
    }
    multipleWhatsapp = (e) => {
        this.setState({
            multipleWhatsapp: e.target.value,
        });
    }

    courierOption = (e) => {
        this.setState({
            courierOption: e.target.value,
        });
    }

    messsage = () => {
        this.props.history.push('/')
    }

    getServiceCharges=()=>{
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('dataa', data)
              let fdata = data.getSiteConfigServices != null && data.getSiteConfigServices != undefined && data.getSiteConfigServices.length > 0 ? data.getSiteConfigServices : []
              let arr = fdata.filter( function(hero){
                return hero.serviceName !=null && hero.serviceName != undefined && hero.serviceName == "SITE"
              })
              delete arr[0].chargesModel;
              console.log("arr",arr)

              this.setState({
                serviceData: data.getSiteConfigServices,
                defaultService:arr,
                // specTypeDisplay: ["MONTHLY"],
                  loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }

    updateRewardSettings = (variables) => {
        // console.log("variables :::", variables)
        // return;
        this.props.client.query({
            query: updateSiteRewardSetting,
            variables
          })
        .then(({ data }) => {
          console.log('updateSiteRewardSetting', data.updateSiteRewardSetting)
        })
        .catch(err => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
        })
    }

    handleSubmit = (e) => {
        // console.log('in')
        e.preventDefault();
        this.props.form.validateFields(async(err, values) => {
            console.log("values ::", values)
            //  return
            if (values.no_of_photos === undefined) {
                delete values.no_of_photos
            }

            if ((values.siteType === "B2C_MARKET_PLACE" || values.siteType === "SIMPLE_B2C") && this.state.paymentOption.length == 0) {
                this.props.form.setFields({
                    paymentOption: {
                        value: values.paymentOption,
                        errors: [new Error("Please Check At Least One Payment Option")]
                    }
                });
                return true;
            } else {
                this.props.form.setFields({
                    paymentOption: {
                        value: values.paymentOption,
                        errors: ''
                    }
                });
            }
            let dcheck = values.subdomain && values.domain && await this.domainCheck(values.subdomain.trim()+'.'+values.domain.trim())
            if (!err && dcheck) {

                this.enterLoading();

                if (Object.keys(this.state.siteLogo).length != 0) {
                    if (!this.state.siteLogo.hasOwnProperty("key")) {
                        values.siteLogo = {
                            bucket: bucket,
                            region: s3_Region,
                            localUri: this.state.siteLogo.originFileObj,
                            key: `${values.subdomain}/site/${uuidv1()}`,
                            mimeType: this.state.siteLogo.type
                        };

                    } else {
                        const { uid, url, bucket, key, region } = this.state.siteLogo
                        values.siteLogo = {
                            bucket,
                            key,
                            region
                        }
                    }
                } else {
                    values.siteLogo = null
                }
                // if(this.state.wallet == true){
                //     this.createSiteConfig(values.charges)
                // }
                // console.log("values.charges", this.state.pages)
                // this.setState({
                //     loading: false,
                //     walletSetting: true,
                //     // siteResp: data.createSiteByRecahoAdmin
                // })

                // return false
                let LatLong = {}

                if(values.latitude && values.longitude){
                    LatLong={
                        lat: parseFloat(values.latitude),
                        long: parseFloat(values.longitude)
                    }
                }

                let variableObject = {
                    domain: values.domain.trim(),
                    subdomain: values.subdomain.trim(),
                    internalName: values.companyName,
                    siteType: values.siteType,
                    enabled: true,
                    siteOptions: values.siteOption,
                    currency: values.currency,
                    ownerEmail: values.ownerEmail,
                    additionalContactNumbers: values.additionalContactNumbers,
                    integratePaymentOption: this.state.paymentOption.length != 0 ? this.state.paymentOption : [],
                    ownerMob: values.ownerMob,
                    no_of_products: values.no_of_products,
                    no_of_photos: values.no_of_photos,
                    custName: values.custName,
                    companyName: values.companyName.trim(),
                    userName: values.userName.trim(),
                    gstNo: values.gstNo,
                    courierOption: this.state.courierOption,
                    homePageTitle: values.homeTitle,
                    homePageMetadesc: values.metaDescription,
                    siteLogo: values.siteLogo,
                    backgroundColor: this.state.bg,
                    menuFontColor: "#800000",
                    textFontColor: "#00000",
                    textFontType: this.state.fontStyle,
                    whatsAppSeller: this.state.whatsappToSeller,
                    whatsAppBuyer: this.state.whatsappToBuyer,
                    emailAppBuyer: this.state.emailToBuyer,
                    feedBackToBuyer: this.state.feedBackToBuyer,
                    inventory: this.state.inventory,
                    frontSite: this.state.frontSite,
                    isGroupAdmin: this.state.isGroupAdmin,
                    groupId: this.state.groupId,
                    availableWhatsApp: values.availableWhatsApp,
                    isWalletEnabled: this.state.wallet,
                    siteMode: this.state.siteMode,
                    footerAddress: {
                        addressLineOne: values.addressLineOne ? values.addressLineOne : undefined,
                        addressLineTwo: values.addressLineTwo ? values.addressLineTwo : undefined,
                        city: values.city ? values.city : undefined,
                        state: values.state ? values.state : undefined,
                        zip: values.zip ? values.zip : undefined,
                        country: values.country ? values.country : undefined,
                        location: LatLong && Object.keys(LatLong).length != 0 ? LatLong : undefined,
                    },
                    isDayClosureEnabled: values.isDayClosureEnabled,
                    salesPerson: values.salesPerson,
                    supportAccManager: values.supportAccManager,
                    channelPartner: values.channelPartner,
                    stockistName: values.stockistName,
                    isCounterEnabled: values.isCounterEnabled,
                    isReturnOrderEnabled: values.isReturnOrderEnabled,
                    isDemoSite: values.isDemoSite,
                    customerLang: values.customerLang,
                    partnerType: values.partnerType,
                    supportNumber: values.showSupportNumber ? this.state.channelPartnerData && this.state.channelPartnerData.supportNumber : null
                }
                if(this.state.paymentModes && this.state.paymentModes != [] && this.state.paymentModes.length && this.state.paymentModes.length > 0){
                    variableObject.paymentModes = this.state.paymentModes
                }

                //  console.log("variableObject ::",variableObject)
                //  return

                this.props.createSiteM({
                    variables: variableObject
                }).then(({ data }) => {
                    console.log("Site Created Successfully", values.charges)

                    if (data.createSiteByRecahoAdmin.siteId && this.state.pages.length != 0) {
                        this.submitPages(data.createSiteByRecahoAdmin.siteId)
                        // this.createOnlineMenu(data.createSiteByRecahoAdmin.siteId)
                      
                    }
                    if(values.isGroupReward){
                        const rewardObject = {
                            isEnabled: true,
                            onGroup: true,
                            groupId: this.state.groupId,
                            domain: data.createSiteByRecahoAdmin.fqdn,
                            siteId: data.createSiteByRecahoAdmin.siteId,
                        }
                        this.updateRewardSettings(rewardObject)
                    }
                    // if(this.state.wallet == true){
                    //     this.createSiteConfig(data.createSiteByRecahoAdmin,values.charges)
                    // }
                    message.success(" Site Created Successfully");
                    this.setState({
                        loading: false,
                        walletSetting: this.state.wallet,
                        siteResp: data.createSiteByRecahoAdmin
                    })
                }).catch(res => {
                    console.log(
                        `Catch: handleFormSubmit: error: `, res
                    );
                });
            } else {
                console.log("Error", err)
                if(!dcheck){
                    message.error("Domain not available");
                    this.setState({
                        loading:false
                    })
                }
                this.setState({
                    loading:false
                })
            }
        })
    }

    channelPartnerChange = (value) => {
      
        let record;
        if (value) {
            this.state.userData && this.state.userData.length > 0 && this.state.userData.filter((ele) => {
                if (ele.userRole == 'CP' && ele.companyName && ele.companyName == value) {
                    record = ele
                }
            })
        }

        this.setState({
            channelPartnerData: record
        })

    }

    onValidityDateChange = (date, dateString) => {
        const epochSec1 = moment(dateString[0], 'YYYY-MM-DD').valueOf()
        const sec1 = epochSec1 / 1000
        const epochSec2 = moment(dateString[1], 'YYYY-MM-DD').valueOf()
        const sec2 = epochSec2 / 1000

        this.setState({
            validFrom: sec1,
            validTo: sec2
        })
    }

    createSiteConfig=(ids,data)=>{
        // console.log("Data in createSiteConfig ::",ids, data)

        let chargeData = data && data.length > 0 ? data : []
        let newCharge = []
        chargeData.map((val)=>{
           let obj = {
            charges:parseInt(val.charges*100),
            chargingModel: val.chargingModel,
            masterStatus: val.masterStatus,
            serviceName: val.serviceName,
            startedAt: val.startedAt,
            status: val.status,
            startDate: val.startDate ? moment(val.startDate).format("YYYY-MM-DD") : undefined,
            endDate: (val.chargingModel == "MONTHLY" || val.chargingModel == "YEARLY") ?  moment(val.startDate).add(val.chargingModel == "MONTHLY" ? 1 : 12, "M").format("YYYY-MM-DD") : undefined,
            updatedAt: val.updatedAt
           }
           newCharge.push(obj)
        })
        // return
        console.log("newCharge",newCharge)
        this.props.client.mutate({
            mutation: createSiteConfig,
            variables: {
                domain: ids.fqdn,
                siteId: ids.siteId,
                enabled: true,
                siteServices: newCharge
            }
        }).then(({ data }) => {
            console.log("createSiteConfig response ::", data)
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ bg: color.hex });
    };
    handleChangeText = (color) => {
        this.setState({ textColor: color.hex });
    };
    handleChangeMenu = (color) => {
        this.setState({ menuColor: color.hex });
    };
    handleChangeLabel = (color) => {
        this.setState({ labelColor: color.hex });
    };
    handleImgClick = () => {
        this.setState({ showPicker: true })
    }
    handleMenuClick = () => {
        this.setState({ showMenu: true })
    }
    handleLabelClick = () => {
        this.setState({ showLabel: true })
    }
    handleTextClick = () => {
        this.setState({ showText: true })
    }

    handleBgClose = (val) => {
        this.setState({ showPicker: false })
    }
    handleMenuClose = (val) => {
        this.setState({ showMenu: false })
    }
    handleMenuComplete = (color) => {
        this.setState({ menuColor: color.hex });
    };
    handleColorMenuClose = () => {
        this.setState({ menuColor: "#ea2b1f", showMenu: false })
    }
    handleFontStyle = (val) => {
        this.setState({ fontStyle: val })
    }
    handleLabelClose = (val) => {
        this.setState({ showLabel: false })
    }
    handleTextClose = (val) => {
        this.setState({ showText: false })
    }

    menuClose = () => {
        this.setState({ menuColor: "#ea2b1f", showMenu: false })
    }

    labelClose = () => {
        this.setState({ labelColor: "#ea2b1f", showLabel: false })

    }

    handleColorClose = () => {
        this.setState({ bg: "#FFFFFF", showPicker: false })
    }

    textClose = () => {
        this.setState({ textColor: "#000000", showText: false })
    }

    handleSiteOption = (value) => {
        if (value === 'ENQUIRY') {
            this.setState({
                displayPay: true,
                noProduct: true,
                shipping: false,
            })

        } if (value === "BOOKING_RESTAURANTS" || value === 'SIMPLE_B2C' || value === "B2C_MARKET_PLACE" || value === "B2B2C_ECOM") {
            this.setState({
                displayPay: false,
                noProduct: false,
                shipping: true
            })
        } else {
            this.setState({
                displayPay: false,
                noProduct: false

            })
        }
        if (value === "BOOKING_RESTAURANTS") {
            let ind = [], data11 = []
            this.state.data.filter((data, index) => {
                // console.log("data,,,",data)
                if (data.pageName == "booking-home-page-mob" || data.pageName == "booking-home-page-web-restaurants" || data.pageName == "footer" || data.pageName == "header") {
                    ind.push(data.id)
                    data11.push(data)
                }

            })
            this.setState({
                selectedRowKeys: ind,
                pages: data11,
                siteOptions1: value
            })
        }
        else {
            this.setState({
                selectedRowKeys: [],
                pages: [],
                siteOptions1: ''

            })
        }


    }
    handleSelectChange = (value) => {
        // console.log("Value..", value);

        if (value === 'SIMPLE_B2C' || value === "B2C_MARKET_PLACE" || value === "B2B2C_ECOM") {
            // console.log("Value.. in If", value);
            PLATFORM = [
                { value: 'COMMERCE', label: 'e-Commerce' },
                { value: 'ENQUIRY', label: 'Enquiry' },
                { value: 'ECOM_CATALOGUE', label: 'Catalogue' },
                { value: 'B2B_GIFTING', label: 'Gifting' },
            ]
            this.setState({

                displayPLP: true,
                siteType: value,
                shipping: true,
                photo: false,
                selectedRowKeys: [],
                pages: [],
                siteOptions1: ''

            })
        } else if(value === 'MARKETING') {
            PLATFORM = [
                { value: 'GENERAL', label: 'General' }
            ]
            this.setState({
                displayPLP: true,
                shipping: false,
                selectedRowKeys: [],
                pages: [],
                siteOptions1: ''
            })
        }
        // else{
        //     this.setState({
        //         displayPLP: false,
        //         shipping: false,
        //         selectedRowKeys: [],
        //         pages: [],
        //         siteOptions1: ''
        //     })
        // }
        if (value === 'O2OCommerce') {
            let ind = [], data11 = []
            this.state.data.filter((data, index) => {
                // console.log("data...",data)
                if (data.pageName == "booking-home-page-mob" || data.pageName == "booking-home-page-web-restaurants" || data.displayName == "Footer" || data.displayName == "Header" || data.displayName == "script-page") {
                    ind.push(data.id)
                    data11.push(data)
                }

            })
            PLATFORM = [
                            // { value: 'ENQUIRY', label: 'Enquiry' },
                            { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
                            { value: 'BOOKING_SALOON', label: 'Saloon' },
                            // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
                            // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
                            { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
                            { value: 'LAUNDRY', label: 'Laundry' },
                            { value: 'EVENT', label: 'Event' },
                            { value: 'VILLA_RENTAL', label: 'Vill Rental' },
                            { value: 'SERVICES', label: 'Services' },
                            { value: 'CAR_RENTAL', label: 'Car Rental' },
                            { value: 'CONSTRUCTION', label: 'Construction' },
                            { value: 'GROCERY', label: 'Grocery' },
                            { value: 'GENERAL', label: 'General' },
                            { value: 'LAWYER', label: 'Lawyer' },
                            { value: 'PET_GROOMERS', label: 'Pet Groomers' },
                            { value: 'REPAIR_SERVICES', label: 'Repair Services' },
                            { value: 'SPA', label: 'Spa' },
                            { value: 'GYM', label: 'Gym' },
                            { value: 'MANUFACTURER', label: 'Manufacturer' },
                            { value: 'JEWELLERY', label: 'Jewellery' },
                            { value: 'MARRIAGE', label: 'Marriage' },
                            { value: 'AUTO_SERVICES', label: 'Auto Services' },
                            { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
                            { value: 'DOCTOR', label: 'Doctor' },
                            { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
                            { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
                            { value: 'WAREHOUSE', label: 'Warehouse' }
            ].sort((a,b)=>a.label.localeCompare(b.label))
            this.setState({
                selectedRowKeys: ind,
                pages: data11,
                photo: true,
                displayPLP: true,
                siteType: value,
                shipping: true,
                siteOptions1: 'BOOKING_RESTAURANTS'
            })
        }
        // else{
        //     console.log("Value.. in else",value);
        //     this.setState({
        //         photo:false,
        //         shipping: false
        //     })
        // }
    }

    onChangeHandle = (selectedRowKeys, selectedRows) => {
        // console.log("sel...",selectedRows,selectedRowKeys)
        // console.log("pages..",this.state.pages)
        // console.log("selectedRowKeys..",this.state.selectedRowKeys)

        if (!this.state.searched) {
            this.setState({
                pages:selectedRows,
                selectedRowKeys: selectedRowKeys,
            }, () => {
                // console.log("...", this.state.pages, this.state.selectedRowKeys)
            })
        }
        else
        if(this.state.searched && selectedRowKeys.length<this.state.selectedRowKeys.length){
            var filteredKeywords = this.state.selectedRowKeys.filter((word) => !selectedRowKeys.includes(word));
                // console.log("remo...",filteredKeywords)
                if(filteredKeywords.length>0){
                    var  arr = this.state.pages.filter(function(el){
                        return el.id !== filteredKeywords[0];
                      });
                      this.setState({
                        pages:arr,
                        selectedRowKeys: selectedRowKeys,
                    }, () => {
                        // console.log("... remove", this.state.pages, this.state.selectedRowKeys)
                    })
                }
        }
        else
        if (this.state.searched && selectedRows.length==selectedRowKeys.length) {
            this.setState({
                pages:selectedRows,
                selectedRowKeys: selectedRowKeys,
            }, () => {
                // console.log("...selected add", this.state.pages, this.state.selectedRowKeys)
            })
        }
        else 
        if (this.state.searched && selectedRowKeys.length>this.state.selectedRowKeys.length) {
            let nArray=this.state.pages.concat(selectedRows)
            // console.log("nArray nArray",nArray)
            const arrayUniqueByKey = [...new Map(nArray.map(item =>
                [item['id'], item])).values()];
            // console.log("nArray",arrayUniqueByKey,selectedRowKeys)
            
            this.setState({
                pages:arrayUniqueByKey,
                selectedRowKeys: selectedRowKeys,
            }, () => {
                // console.log("...selected add if add then search add", this.state.pages, this.state.selectedRowKeys)
            })
        }
        else
        
        if(selectedRows.length==0 && selectedRowKeys.length==0){
            this.setState({
                pages:selectedRows,
                selectedRowKeys: selectedRowKeys,
            })
        }
        
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }
    siteHandleChange = ({ file }) => {
        // this.setState({ siteLogo : file })
        this.setState((prevState, props) => {
            if (
                Object.keys(prevState.siteLogo).length === 0 &&
                prevState.removedMainImage === false
            ) {
                return {
                    siteLogo: file,
                    removedMainImage: false
                };
            } else {
                return {
                    removedMainImage: false
                };
            }
        });
    }
    beforeUpload = (file) => {
        var fr = new FileReader;

        fr.onload = () => { // file is loaded
            var img = new Image;
            img.onload = () => {
                if (img.width != 600 || img.height != 200) {
                    this.setState({
                        siteLogoErr: true
                    })
                    this.props.form.setFields({
                        siteLogo: {
                            value: this.state.siteLogo,
                            errors: [new Error('Please Upload image with (600 px * 200 px) dimensions.')]
                        }
                    })
                    return false
                } else {
                    this.setState({
                        siteLogoErr: false
                    })
                }
            };

            img.src = fr.result; // is the data URL because called with readAsDataURL

        };
        fr.readAsDataURL(file); // I'm using a <input type="file"> for demonstrating
    }
    handleRemove = () => {
        this.setState({ siteLogo: {}, removedMainImage: true });
        // this.props.form.resetFields(['siteLogo'])
        return true;
    }
    handleCancel = () => this.setState({ previewVisible: false })
    createOnlineMenu = (siteId) => {
        this.props.client.mutate({
            mutation: createServiceType,
            variables: {
                siteId: 'serviceType::' + siteId,
                slugName: 'online-menu',
                typeName: 'Online Menu',
                typeDes: undefined,
                image: undefined,
                typeStatus: true,
                displayOrder: 1
            }
        }).then(({ data }) => {
            // console.log("data response", data)
        })
    }
    createDineInMenu = (siteId) => {
        this.props.client.mutate({
            mutation: createServiceType,
            variables: {
                siteId: 'serviceType::' + siteId,
                slugName: 'dine-in',
                typeName: 'Dine In',
                typeDes: undefined,
                image: undefined,
                typeStatus: true,
                displayOrder: 2
            }
        }).then(({ data }) => {
            // console.log("data response", data)
        })
    }
    submitPages = (siteId) => {
        let pagesData = []
        this.state.pages.map((val, i) => {
            let img={}
            if (val.image != null) {
              let obj = { ...val.image }
              delete obj.__typename
              img=obj
            }
            else{
              img=null
            }
            let pageData = {
                siteId: siteId,
                pageName: val.pageName,
                displayName: val.displayName,
                value: val.value,
                onFooter: val.onFooter,
                onHome: val.onHome,
                displayOrder: i,
                pageFor:val.pageFor,
                pageType:val.pageType,
                tags:val.tags,
                image:img
            }
            pagesData.push(pageData)
        })
        this.enterLoading()
        this.props.sitePages({
            variables: {
                input: pagesData
            }
        }).then(({ data }) => {
            // console.log("data..",data)
            this.setState({
                selectedRowKeys:[],
                pages:[]
            })
        })
    }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      }
    
      handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
      }

      
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
        id="search"
          ref={node => { this.searchInput = node; }}
          placeholder={`Search Page Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
        id="search"
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })


  onchangeSearch=(val)=>{
    if(val!='' && val!=null){
      let vall=val.toLowerCase()
      // console.log("vall..",vall)
      let arr = this.state.data.filter((data) => {
        if (data.value && data.value.toLowerCase().includes(vall) || data.pageName && data.pageName.toLowerCase().includes(vall)) {
            return data
        }
    })
    this.setState({
        data11: arr,
        searched: true
    })

    }
    else{
      this.setState({
        searched:false,
        data11:[]
      })
    }
  }

  userNameChange=(e)=>{
    let value = e.target.value
        this.setState({
            username:value
        })
        if(value.length >= 6){
            this.checkUserName(value)
        }else{
            this.setState({
                userNameCheck: true
            })
        }
}
checkUserName=(value)=>{
    // console.log("client",this.props.client)
    this.props.client.mutate({
        mutation: verifyUsername,
        variables: {
            username: value
        }
    }).then(({ data }) => {
        console.log("Response ::",data)
        this.setState({
            userNameCheck: data.verifyUsername
        })
    })
}
handleFocus = e => {
    this.setState({ showError: true });
  };

    handleAddPaymentModes = (internalName, paymentState, displayName) => {
        if(!internalName || internalName == "" || !displayName || displayName == "") {
            message.error("Please Input Payment Mode and Display Name first", 1.6)
            return;
        }
        let paymentModes = this.state.paymentModes, paymentModesAvailable = JSON.parse(JSON.stringify(this.state.paymentModesAvailable));
        console.log("log ::", internalName, displayName)
        paymentModes.push({internalName, displayName, paymentState})
        paymentModesAvailable.forEach((paymentMode) => {
            if(paymentMode.internalName == internalName){
                paymentMode.disabled = true;
            }
        })
        console.log("paymentModes ::", paymentModes)
        this.setState({paymentModes, paymentModesAvailable, internalName: "", displayName: "", paymentState: ""})
        this.props.form.resetFields(["internalName", "displayName"])
    }

    handleDeletePaymentModes = record => {
        let paymentModes = this.state.paymentModes, paymentModesAvailable = JSON.parse(JSON.stringify(this.state.paymentModesAvailable));
        console.log(" record: ", record)
        paymentModes = paymentModes.filter((paymentMode) => (paymentMode.internalName != record.internalName && paymentMode.displayName != record.displayName))
        paymentModesAvailable.forEach((paymentMode) => {
            if(paymentMode.internalName == record.internalName){
                paymentMode.disabled = false;
            }
        })
        this.setState({paymentModes, paymentModesAvailable})
    }    
    partnerTypeChange=(value)=>{
        this.setState({
            partnerType: value
        })
    }
    onCountryChange=async(value)=>{
        let index = country.findIndex((x)=>x.value == value)
     let response = await getStateByCountry(country[index].key)
     console.log("response ::",response)
     if(response){
     this.setState({
        selectedCountry: value,
      allState:response
     })
    }
  }
  onStateChange=async(value)=>{
    let cIndex = country.findIndex((x)=>x.value == this.state.selectedCountry)
    let sIndex = this.state.allState.findIndex((x)=>x.name === value)
    let cities = await getcitiesByStateAndCountry(country[cIndex].key,this.state.allState[sIndex].iso2)
    this.setState({
      selectedState: value,
      allCities: cities
    })
  }
  onCityChange=(value)=>{
      this.setState({
        selectedCity: value
      })
  }

  handleKeyDown = (event) => {
    if ( event.key !== 'Backspace' &&  event.key !== 'Delete' && !/[0-9\b]/.test(event.key)) {
      event.preventDefault();
    }
    if (event.target.value.length >= 10 && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();
      }
  };
  

    render() {
        const { getFieldDecorator, getFieldValue} = this.props.form;
        const { selectedRowKeys } = this.state;
        // console.log("this.props.masterData",this.props.location.state.masterData)
        const validateFloatNumber = (rule, value, callback) => {
            // Regular expression to check if the value is a float number (positive or negative)
            const floatRegex = /^-?\d+(\.\d+)?$/;
        
            if (!value || floatRegex.test(value)) {
              callback(); // Validation passed
            } else {
              callback('Please enter a valid float number.'); // Validation failed
            }
          };
        const columns = [
          
                {
                    title: 'Page Name',
                    dataIndex: 'displayName',
                    key: 'pageName',
                    width: '20%',
            // ...this.getColumnSearchProps('pageName'),
    
                    // filters: tags,
                    // filterSearch: true,
                    // onFilter: (value, record) => {
                    //     if (record.tags != null) {
                    //         return record.tags.find(x => x == value)
                    //     }
                    // },
                },
                {
                    title: 'Page Tag',
                    // dataIndex: 'tags',
                    key: 'tags',
                    width: '15%',
                    filters: this.state.tags,
                    filterSearch: true,
                    render: (data) => {
                        // console.log("data..",data)
                        return (
                            <div>
                            {data && data.tags && data.tags.length>0 ? data.tags.map((val)=>{
                                return(
                                    <Tag color="green" key={val}>
                                        {val}
                                    </Tag> 
                                )
                            }):
                            <span>--</span>
                            }
                            </div>
                        )
                    },
                    onFilter: (value, record) => {
                        if (record.tags != null) {
                            return record.tags.find(x => x == value)
                        }
                    },
                },
                {
                    title: 'Display',
                  //   dataIndex: 'pageFor',
                    key: 'pageFor',
                    width: '10%',
                    render: (data)=>{
                        let check = data.pageFor == null ? "BOTH" : data.pageFor
                        return(
                            <span>{check == "MOB" ? "Mobile View" : check == "WEB" ? "Web View" : check}</span>
                        )
                    }
                },
                {
                    title: 'Page Type',
                  //   dataIndex: 'pageType',
                    key:'pageType',
                    width:'15%',
                    render: (data)=>{
                      return(
                          <span>{data.pageType == null ? "FROALA" : data.pageType}</span>
                      )
                    }
                },
                {
                    title: 'Preview Image',
                  //   dataIndex: 'pageType',
                    key:'pageType',
                    width: '40%',
                    align:'center',
                    render: (record) => {
                        return record.image != null ? (
                          <img
                            src={process.env.REACT_APP_S3_URL + '' + record.image.key}
                            style={{ width: '100%' }}
                            onClick={()=>{this.setState({fullImg:true,fullImage: process.env.REACT_APP_S3_URL + '' + record.image.key})}}
                          />
                        ) : (
                          '--'
                        );
                      }
                },
              
            
        ]

        const paymentModeColumns = [
            {
              title: "Payment Mode",
              dataIndex: "internalName",
              key: "internalName",
              width: "14%",
              render: (internalName) => {
                return(<span style={{color: "black"}}>{internalName}</span>)
              }
            },
            {
                title: "Display Name",
                dataIndex: "displayName",
                key: "displayName",
                width: "14%",
                // render: (internalName) => {
                //   return(<span style={{color: "black"}}>{internalName}</span>)
                // }
              },
            {
              title: "Action",
              key: "action",
              align: "center",
              width: "10%",
              render: (record) => {
                console.log("record",this.state.selectedRecord)
                return (
                    <div>
                        <Tooltip placement="top" title="Delete Payment Mode">
                          <Popconfirm
                            title="Sure to delete?"
                            onConfirm={() => this.handleDeletePaymentModes(record)}
                          >
                            <Button
                              type="danger"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                background: "#ff0000 ",
                                color: "#fff",
                              }}
                            >Delete
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                    </div>
                );
              },
            },
        ];

        const { previewImage, previewVisible, siteLogo } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onChangeHandle,
            // getCheckboxProps: (record) => {
            //     const rowIndex = state11.state.siteOptions1 == "BOOKING_RESTAURANTS"
            //     return {
            //       disabled: rowIndex ? record.pageName == "booking-homepage-restaurant-new-mob" || record.pageName == "Booking Home Page - Web - Restaurants" || record.pageName == "Footer" || record.pageName == "Header":null,
            //     };
            //   },

        };
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
      
        let displayPLP = ''
        this.state.displayPLP ? displayPLP = "block" : displayPLP = "none"
        let photo = ''
        this.state.photo ? photo = "block" : photo = "none"
        // let displayPay = ''
        // this.state.displayPay ? displayPay="block" : displayPay="none"
        return (
            <App header={'Create New Site'}>
                    {this.state.walletSetting == false ?
                <Form onSubmit={this.handleSubmit} >
                    <div>
                        <Button id="submit" type="primary" loading={this.state.loading} disabled={this.state.loading} htmlType="submit" style={{ float: "right", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                            Submit
                 </Button>
                        <Divider style={{ fontSize: "20px" }}>Basic</Divider>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Customer Name' >
                                    {getFieldDecorator('custName', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter customer name.",
                                        }],
                                    })(

                                        <Input placeholder="Customer Name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Email' >
                                    {getFieldDecorator('ownerEmail', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter email.",
                                        }, {

                                            validator: (rule, value, cb) => {
                                                if (value) {
                                                    if (!value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                                        cb('Please enter valid email address')
                                                    }
                                                }
                                                cb()
                                            }
                                        },
                                        ],

                                    })(

                                        <Input placeholder="Email" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Mobile' >
                                    {getFieldDecorator('ownerMob', {
                                        rules: [{
                                            required: true,
                                            message: "Please enter mobile number.",
                                        },
                                        // {
                                        //     validator:(rule, value, cb)=>{
                                        //     if(value!=undefined && value!=''){
                                        //      let reggst = /^\d{10}$/;
                                        //      if(!reggst.test(value)){
                                        //             cb('Please Enter Valid Mobile Number')
                                        //        }
                                        //     }
                                        //     cb()
                                        //     }
                                        // }
                                    ],
                                    })(

                                        <Input placeholder="Mobile Number"  style={{ width: '100%' }} type='number' />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label="Additional Contact Numbers"  >
                                    {getFieldDecorator(`additionalContactNumbers`, {
                                          
                                        rules: [{
                                            required: false,
                                            message: "Please Enter Additional Contact Numbers",
                                        },
                                        {
                                            validator: (rule, value, cb) => {
                                                if (value) {
                                                //    for(let i=0;i<value.length;i++){
                                                //     if(value[i].length != 10){
                                                //      cb(`Please Enter valid Number`)
                                                //     }
                                                // }
                                                   
                                                }
                                                cb()
                                            }
                                        },
                                    ]
                                    })(
                                        <Select mode="tags" style={{ width: '100%', marginRight: 8 }} onInputKeyDown={this.handleKeyDown} placeholder="Enter Number"  >
                                            {children}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        {/* </Row>

                        <Row gutter={64}> */}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='User Name' 
                                hasFeedback 
                                help={this.state.showError && this.state.userNameCheck  ? <span style={{color:'red'}}>This username is not available. Please try another one</span> : ""}
                                validateStatus={this.state.userNameCheck ? "warning" : "success"}>
                                    {getFieldDecorator('userName', {
                                        // validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true, 
                                            message: 'Please input username!',
                                            min: 6,
                                            max:15 
                                        }],
                                    })(

                                        <Input placeholder="Enter Username" onFocus={this.handleFocus} onChange={this.userNameChange}  id={this.state.userNameCheck ? "warning" : "success"}  style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Company Name' >
                                    {getFieldDecorator('companyName', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter company name.",
                                        }],
                                    })(

                                        <Input placeholder="Company Name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='GST Number' >
                                    {getFieldDecorator('gstNo', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                    })(

                                        <Input placeholder="Gst No" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem
                                    label="Validity (From - Till)">
                                    {getFieldDecorator(`validFrom`, {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please select Date.",
                                            }
                                        ],
                                    })(

                                        <RangePicker onChange={this.onValidityDateChange}
                                            format={'YYYY-MM-DD'}
                                            style={{ width: '100%' }}
                                        />
                                        // <DateRange getDates={this.getDates}/>
                                    )}
                                </FormItem>
                            </Col> */}
                           
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Sub Domain' >
                                    {getFieldDecorator('subdomain', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter sub domain.",
                                        }],
                                    })(

                                        <Input placeholder="Sub Domain" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>


                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Domain' >
                                    {getFieldDecorator('domain', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter domain.",
                                        }],
                                    })(

                                        <Input placeholder="Domain" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Currency' >
                                    {getFieldDecorator('currency', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: "INR",
                                        rules: [{
                                            required: true,
                                            message: "Please enter currency.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select">
                                            {currency.map((c) => {
                                                return (
                                                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Site Type'>
                                    {getFieldDecorator('siteType', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter site type.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            onChange={this.handleSelectChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Site Type">
                                            {type.map((c) => {
                                                return (
                                                    <Select.Option key={c.key} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            {this.state.displayPLP &&
                                <div>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                        <FormItem label='Site Option' style={{ display: displayPLP }}>
                                            {getFieldDecorator('siteOption', {
                                                validateTrigger: ['onChange', 'onBlur'],
                                                initialValue: this.state.siteType == 'O2OCommerce' ? 'BOOKING_RESTAURANTS' : '',
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter site option.",
                                                }],
                                            })(
                                                <Select
                                                    optionFilterProp="children"
                                                    showSearch
                                                    allowClear
                                                    onChange={this.handleSiteOption}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    placeholder="Please select">
                                                    {PLATFORM.map((c) => {
                                                        return (
                                                            <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                    {!this.state.noProduct && this.state.siteType != "O2OCommerce" && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                        <FormItem label='Number of Products Allowed' style={{ display: displayPLP }}>
                                            {getFieldDecorator('no_of_products', {
                                                validateTrigger: ['onChange', 'onBlur'],
                                                rules: [{
                                                    required: this.state.displayPLP,
                                                    message: "Please enter number.",
                                                }],
                                            })(
                                                <Input placeholder="No of product allowed" style={{ width: '100%' }} />
                                            )}
                                        </FormItem>
                                    </Col>}
                                </div>
                            }
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Site Mode' >
                                    <RadioGroup onChange={this.siteMode} value={this.state.siteMode}>
                                        <Radio value='UNDER_CONSTRUCTION' >Under Construction</Radio>
                                        <Radio value="LIVE">Live</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Sales Person' >
                                    {getFieldDecorator('salesPerson', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter sales person name.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Sales Person">
                                                {this.state.userData && this.state.userData.length > 0 &&
                                                this.state.userData.map((ele)=>{
                                                    if(ele.userRole == 'SALES_PERSON'){
                                                    return(
                                                        <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                    )
                                                }
                                                })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Support Account Manager' >
                                    {getFieldDecorator('supportAccManager', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: true,
                                            message: "Please enter Support Account Manager name.",
                                        }],
                                    })(
                                        <Select
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        showSearch
                                        allowClear
                                        placeholder="Please select Sales Person">
                                            {this.state.userData && this.state.userData.length > 0 &&
                                            this.state.userData.map((ele)=>{
                                                if(ele.userRole == 'SUPPORT_ACCOUNT_MANAGER' || ele.userRole == 'CUSTOMER_SUPPORT_LEAD'){
                                                return(
                                                    <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                )
                                            }
                                            })}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Partner Type' >
                                    {getFieldDecorator('partnerType', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: false,
                                            message: "Please enter Partner Type.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            onChange={this.partnerTypeChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Customer Language">
                                                    <Select.Option key={'cp'} value={'CP'} >CP</Select.Option>
                                                    <Select.Option key={'reseller'} value={'RESELLER'} >Reseller</Select.Option>
                                                    <Select.Option key={'referral'} value={'REFERRAL'} >Referral</Select.Option>
                                                    <Select.Option key={'stockist'} value={'STOCKIST'} >Stockist</Select.Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            {this.state.partnerType && this.state.partnerType == 'CP' &&
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Partner Name' >
                                    {getFieldDecorator('channelPartner', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: false,
                                            message: "Please enter Partner Name.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            onChange={this.channelPartnerChange}
                                            // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Partner Name">
                                                {this.state.userData && this.state.userData.length > 0 &&
                                                this.state.userData.map((ele)=>{
                                                    if(ele.userRole == 'CP' && ele.companyName){
                                                    return(
                                                        <Select.Option key={ele.companyName} value={ele.companyName} >{ele.companyName}</Select.Option>
                                                    )
                                                }
                                                })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            }
                            {  this.state.partnerType && this.state.partnerType == 'CP' && this.state.channelPartnerData && this.state.channelPartnerData.supportNumber != null &&
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Customer support Number'>
                                {getFieldDecorator('showSupportNumber', {
                                  valuePropName: 'checked',
                                  initialValue: false,
                                })(
                                  <Checkbox>
                                    Show Customer Support Number
                                  </Checkbox>
                                )}
                              </FormItem>
                              </Col>
                            }
                            {this.state.partnerType && this.state.partnerType == 'stockist' &&
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Stockist Name' >
                                    {getFieldDecorator('stockistName', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: false,
                                            message: "Please enter Stockist Name.",
                                        }],
                                    })(
                                        <Select
                                        optionFilterProp="children"
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="Please select Partner Name">
                                            {this.state.userData && this.state.userData.length > 0 &&
                                            this.state.userData.map((ele)=>{
                                                if(ele.userRole == 'STOCKIST'){
                                                return(
                                                    <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                )
                                            }
                                            })}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            }
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Customer Language'>
                                    {getFieldDecorator('customerLang', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter Customer Language.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            onChange={this.handleSelectChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Customer Language">
                                                    <Select.Option key={'english'} value={'english'} >English</Select.Option>
                                                    <Select.Option key={'hindi'} value={'hindi'} >Hindi</Select.Option>
                                                    <Select.Option key={'marathi'} value={'marathi'} >Marathi</Select.Option>
                                                    <Select.Option key={'punjabi'} value={'punjabi'} >Punjabi</Select.Option>
                                                    <Select.Option key={'Gujarati'} value={'Gujarati'} >Gujarati</Select.Option>
                                                    <Select.Option key={'Malayalam'} value={'Malayalam'} >Malayalam</Select.Option>
                                                    <Select.Option key={'Tamil'} value={'Tamil'} >Tamil</Select.Option>
                                                    <Select.Option key={'Telugu'} value={'Telugu'} >Telugu</Select.Option>
                                           
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Latitude' className="booking-label">
                                    {getFieldDecorator('latitude', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter ",
                                        },{
                                            validator:validateFloatNumber
                                        }],
                                    })(<Input placeholder='Latitude'/>)}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Longitude' className="booking-label">
                                    {getFieldDecorator('longitude', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter ",
                                        },{
                                            validator:validateFloatNumber
                                        }],
                                    })(<Input placeholder='Longitude' />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Is Demo site' className="booking-label">
                                    {getFieldDecorator('isDemoSite', {
                                        initialValue: false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter ",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            </Row>

                        <Row gutter={64}>
                            
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Number of Photos Allowed' style={{ display: photo }}>
                                    {getFieldDecorator('no_of_photos', {
                                        initialValue: 20,
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: this.state.photo,
                                            message: "Please enter No. of photo.",
                                        }],
                                    })(
                                        <Input placeholder="No of photo allowed" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                          
                            {this.state.shipping && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Shipping Option' style={{ display: displayPLP }}>
                                    <RadioGroup onChange={this.courierOption} value={this.state.courierOption}>
                                        <Radio value='SELF'>Self</Radio>
                                        <Radio value="SHIPROCKET">ShipRocket</Radio>
                                        <Radio value="DUNZO">Dunzo</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>}

                        </Row>
                        {/* {!this.state.displayPay &&
                            <>
                                <Divider style={{ fontSize: "20px", display: displayPLP }} >Online Payment</Divider>
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <FormItem label='Payment Option' style={{ display: displayPLP }}>
                                            {getFieldDecorator('paymentOption', {
                                                // validateTrigger: ['onChange', 'onBlur'],
                                            })(
                                                <CheckboxGroup options={payOptions} onChange={this.onChangePayment} />
                                            )}
                                        </FormItem>

                                    </Col>
                                </Row>
                            </>} */}
                        <Divider style={{ fontSize: "20px" }} >Wallet Settings</Divider>
                        <Row gutter={64}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24}}>
                                <FormItem label='Wallet Enabled' >
                                    <RadioGroup onChange={this.walletchange} value={this.state.wallet}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                                {/* {this.state.wallet == true &&
                                <>
                                {formItems}
                                <FormItem {...formItemLayoutWithOutLabel}>
                                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                                    <Icon type="plus" /> Add Service
                                </Button>
                                </FormItem>
                                </>
                                } */}
                            </Col> 
                        </Row>

                        <>
                                <Divider style={{ fontSize: "20px" }} >Payment Modes</Divider>
                                    <Row>
                                            <Row gutter={24}>
                                                <Col xs={{span:10}} sm={{span:10}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                                                <FormItem className="booking-label" label="Payment Mode" >
                                            {getFieldDecorator(`internalName`, {
                                                    rules: [{
                                                    required: false,
                                                    message: "Please Select Payment Mode",
                                                        }]
                                            })(
                                                <Select getPopupContainer={trigger => trigger.parentNode} placeholder="Select Payment Mode" onChange={(value) => this.setState({internalName: value.split("::")[0], paymentState: value.split("::")[1]})}>
                                                    {this.state.paymentModesAvailable.map(paymentMode => <Select.Option value={`${paymentMode.internalName}::${paymentMode.paymentState}`} disabled={paymentMode.disabled}>{paymentMode.internalName}</Select.Option> )}
                                                </Select>    
                                            )}</FormItem>
                                                </Col>
                                                <Col xs={{span:10}} sm={{span:10}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                                                <FormItem className="booking-label" label="Display Name">
                                            {getFieldDecorator(`displayName`, {
                                                    rules: [{
                                                    required: false,
                                                    message: "Please Enter Unit",
                                                        }]
                                            })(
                                                <Input placeholder="Enter Display Name For the Selected Payment Mode" onChange={(event) => this.setState({displayName: event.target.value})} />)}</FormItem>
                                                </Col>
                                                <Col span={4}>
                                                    <Button
                                                    type="default"
                                                    onClick={() => this.handleAddPaymentModes(this.state.internalName, this.state.paymentState, this.state.displayName)}
                                                    style={{
                                                        cursor: "pointer",  background: "#389e0d", color: "#fff", padding: "0 2rem", marginTop: "44px"
                                                    }}
                                                    >ADD
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Table dataSource={this.state.paymentModes?this.state.paymentModes:[]} columns={paymentModeColumns} />
                                            </Row>
                                    </Row>
                            </>

                        <Divider style={{ fontSize: "20px" }}>Settings</Divider>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label="Site Logo (Upload image with (600 px * 200 px) dimensions)" className="booking-label">
                                    {getFieldDecorator('siteLogo', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: false,
                                            message: " Images are required"
                                        }]
                                    })(
                                        <Upload
                                            listType="picture-card"
                                            onPreview={this.handlePreview}
                                            onChange={this.siteHandleChange}
                                            beforeUpload={this.beforeUpload}
                                            onRemove={this.handleRemove}
                                        >
                                            {Object.keys(siteLogo).length != 0 ? null : uploadButton}
                                        </Upload>
                                    )}

                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <div>
                                            <img id="sitelogo" key="sitelogo" style={{ width: '100%' }} src={previewImage} />
                                        </div>
                                    </Modal>
                                </FormItem>

                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Home Page Title (Used for SEO)' className="booking-label">
                                    {getFieldDecorator('homeTitle', {
                                        initialValue: this.state.siteType == 'O2OCommerce' ? "Online Food delivery near me | Home delivery food near me | Order from website | Popular Takeaway Service restaurant | Centre in Pune | Healthy Homemade homestyle cooked taste" : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter home page title.",
                                        }],
                                    })(

                                        <Input placeholder="Home Page Title" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Home Page Meta Description(256 Characters - Used for SEO)'
                                    className="booking-label" >
                                    {getFieldDecorator('metaDescription', {
                                        initialValue: this.state.siteType == 'O2OCommerce' ? "Order from menu now. Get it packaged and delivered fast at your doorstep.Eat Daily Best and Tasty Indian food Veg, Non- Veg meals from this favourite Top rated and Trusted local restaurant nearby. We serve Breakfast, Lunch, Dinner or Snacks by Best chefs." : '',
                                        rules: [{
                                            max: 256,
                                        }],
                                    })(

                                        <TextArea rows={4} placeholder="Home Page Meta Description" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                <FormItem label='Address Line 1' className="booking-label">
                                    {getFieldDecorator('addressLineOne', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 1",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 1" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Address Line 2' className="booking-label">
                                    {getFieldDecorator('addressLineTwo', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 2",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 2" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Country' className="booking-label">
                                    {getFieldDecorator('country', {
                                        rules: [{
                                            required: true,
                                            message: "Please enter country",
                                        }],
                                    })(

                                        <Select onChange={this.onCountryChange}>
                                            {country.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value} >{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            
                            
                        </Row>
                        <Row gutter={64}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='State' className="booking-label" >
                                    {getFieldDecorator('state', {
                                        rules: [{
                                            required: true,
                                            message: "Please enter state",
                                        }],
                                    })(

                                        <Select 
                                            optionFilterProp="children"
                                            style={{ width: '100%',marginRight: '10px' }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="State"
                                            value={this.state.selectedState}
                                            showSearch
                                            allowClear
                                            onChange={this.onStateChange}>
                                            {this.state.allState && this.state.allState.length >0 && this.state.allState.map((c) => {
                                                return (
                                                    <Option key={c.iso2} value={c.name} >{c.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='City' className="booking-label">
                                    {getFieldDecorator('city', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter city.",
                                        }],
                                    })(

                                        <Select 
                                        optionFilterProp="children"
                                        style={{ width: '100%',marginRight: '10px' }}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="City"
                                        value={this.state.selectedCity}
                                        showSearch
                                        onChange={this.onCityChange}>
                                                                    {this.state.allCities && this.state.allCities.length >0 && this.state.allCities.map((c) => {
                                                                        return (
                                                                            <Option key={c.id} value={c.name} >{c.name}</Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Zip' className="booking-label">
                                    {getFieldDecorator('zip', {
                                        rules: [{
                                            required: false,
                                            message: "Please enter zip.",
                                        }],
                                    })(
                                        <Input placeholder="Zip" />
                                    )}
                                </FormItem>
                            </Col>
                           
                        </Row>
                        {/* <Divider style={{ fontSize: "20px" }}>Color and Font</Divider> */}
                        {/* <Row gutter={64}>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 6 }}>
                                <FormItem label='Background Color' className="booking-label">
                                    <Button icon="edit" style={{ marginBottom: "5px" }} onClick={this.handleImgClick} />
                                    <div><div style={{ background: this.state.bg == "" ? "#FFFFFF" : this.state.bg, width: "20px", height: "20px", fontSize: "30px", border: '1px solid #ddd' }}></div></div>
                                    {this.state.showPicker &&
                                        <ReactColor
                                            showPicker={this.state.showPicker}
                                            onCancel={this.handleColorClose}
                                            onAccept={this.handleBgClose}
                                            onChangeComplete={this.handleChangeComplete}
                                            color={this.state.bg}
                                        />
                                    }
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Menu Color' >
                                    <Button icon="edit" style={{ marginBottom: "5px" }} onClick={this.handleMenuClick} />
                                    <div><div style={{ background: this.state.menuColor == "" ? "##ea2b1f" : this.state.menuColor, width: "20px", height: "20px", fontSize: "30px", border: '1px solid #ddd' }}></div></div>
                                    {this.state.showMenu &&
                                        <ReactColor
                                            showPicker={this.state.showMenu}
                                            onCancel={this.handleColorMenuClose}
                                            onAccept={this.handleMenuClose}
                                            onChangeComplete={this.handleMenuComplete}
                                            color={this.state.menuColor}
                                        />
                                    }
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Text Font Color' className="booking-label">
                                    <Button icon="edit" style={{ marginBottom: "5px" }} onClick={this.handleTextClick} />
                                    <div><div type="bg-colors" style={{ background: this.state.textColor == "" && this.state.textColor != null ? "#000000" : this.state.textColor, fontSize: "30px", width: "20px", height: "20px", border: '1px solid #ddd' }}></div></div>
                                    {this.state.showText &&
                                        <PhotoshopPicker
                                            color={this.state.textColor}
                                            onChangeComplete={this.handleChangeText}
                                            onAccept={this.handleTextClose}
                                            onCancel={this.textClose}
                                        />
                                    }
                                </FormItem>
                            </Col>
                        </Row> */}
                        {/* <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Select Font Style' className="booking-label">
                                    <Select
                                        showSearch
                                        placeholder="Search to Select Font Style"
                                        optionFilterProp="children"
                                        style={{ width: "100%", marginRight: 8 }}
                                        onChange={this.handleFontStyle}
                                    >
                                        {fontType.map(tvalue => {
                                            // console.log('value', tvalue)
                                            return (
                                                <Option key={tvalue.value}>{tvalue.value}</Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row> */}
                        <Divider style={{ fontSize: "20px" }}>Premium Features</Divider>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Order Whatsapp to Seller ' >
                                    <RadioGroup onChange={this.whatsappToSeller} value={this.state.whatsappToSeller}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Order Whatsapp to Buyer' >
                                    <RadioGroup onChange={this.whatsappToBuyer} value={this.state.whatsappToBuyer}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Order Email to Buyer' >
                                    <RadioGroup onChange={this.emailToBuyer} value={this.state.emailToBuyer}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Whatsapp To Buyer After Order Completion' >
                                    <RadioGroup onChange={this.feedBackToBuyer} value={this.state.feedBackToBuyer}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Enable Inventory' >
                                    <RadioGroup onChange={this.inventory} value={this.state.inventory}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Enable Front Site' >
                                    <RadioGroup onChange={this.frontSite} value={this.state.frontSite}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Is Group Admin' >
                                    <RadioGroup onChange={this.isGroupAdmin} value={this.state.isGroupAdmin}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Enable Day Closure' className="booking-label">
                                    {getFieldDecorator('isDayClosureEnabled', {
                                        initialValue: false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Is Counter Enabled' className="booking-label">
                                    {getFieldDecorator('isCounterEnabled', {
                                        initialValue: false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Is Manage Return Enabled' className="booking-label">
                                    {getFieldDecorator('isReturnOrderEnabled', {
                                        initialValue: false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            {!this.state.isGroupAdmin &&
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Reference site' >
                                        <Select
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select"
                                            onChange={this.referenceSite}>
                                                <Select.Option key={"null"} value={null} >-</Select.Option>
                                            {this.props && this.props.location && this.props.location.state && this.props.location.state.masterData && this.props.location.state.masterData.length > 0 && this.props.location.state.masterData.map((c) => {
                                                return (
                                                    <Select.Option key={c.siteId} value={c.siteId} >{c.fqdn}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                </FormItem>
                            </Col>
                            }
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Allow Whatsapp Numbers' >
                                    {getFieldDecorator('availableWhatsApp', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: 1,
                                        rules: [{
                                            required: false,
                                            message: "Please enter User Name.",
                                        }],
                                    })(

                                        <Input type="number" style={{ width: '50%' }} />
                                    )}
                                </FormItem>
                            </Col>
                           
                            {
                                this.state.groupId &&  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Group Reward System' className="booking-label">
                                    {getFieldDecorator('isGroupReward', {
                                        initialValue: false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            }
                        </Row>
                       
                        <Divider style={{ fontSize: "20px" }}>Static Pages</Divider>
                <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={(e)=>{this.onchangeSearch(e.target.value)}} style={{marginLeft:'10px',width:'25%',marginBottom:'5px',borderRadius:'5px'}} placeholder="Search for Page"/>

                       {this.state.pages.length>0 &&<strong style={{marginLeft:'25px',color:'blue'}}>{this.state.pages.length} {this.state.pages.length==1 ? "page " : "pages "}selected</strong>}
                        {this.state.data.length != 0 && <Table rowKey="id" columns={columns} scroll={{ y: '10%' }} rowSelection={rowSelection} dataSource={this.state.searched?this.state.data11: this.state.data} 
                        pagination={{ defaultPageSize: 20 }}
                        />}
                   
                        <Modal
                                centered
                                  visible={this.state.fullImg}
                                  onOk={()=>{this.setState({fullImg: false,fullImage: "",fullImg:false})}}
                                  onCancel={()=>{this.setState({fullImg: false,fullImage: "",fullImg:false})}}
                                  footer={null}
                                  width={800}
                                >
                                  <div>
                                {this.state.fullImage != '' ?
                                 
                                <div style={{height:'550px' }}>
                                <PinchZoomPan doubleTapBehavior="zoom" position="center" 
                                // zoomButtons={false}
                                >
                                   <img src={this.state.fullImage} 
                                     //  style={{width:"100%", height:"auto"}} 
                                   />
                               </PinchZoomPan>
                             </div>
                                :
                                <h4>No Image Found !</h4>
                                }
                                  </div>
                                 </Modal>

                    </div>
                </Form>
                    : 
                    <NewSiteSP siteResp={this.state.siteResp} isEdit={false} recData={null}/>
                            }
                    {/* <NewSiteSP siteResp={this.state.siteResp} isEdit={false} recData={null} /> */}

            </App>
        )
    }
}

const WrapCreateSite = Form.create()(CreateSite);
const wrapsite = compose(
    withApollo,
    graphql(CreateSiteM, {
        name: 'createSiteM'
    }),
    graphql(SitePages, {
        name: 'sitePages'
    }),
    graphql(getSitePagesById, {
        options: props => ({
            fetchPolicy: "network-only"
        })
    })

)(WrapCreateSite)
export default wrapsite