import React, { useEffect, useState } from "react";
import moment from "moment";
import { withApollo } from "react-apollo";
import { Table, Row, Col, Select, Input, Button, Icon, Modal } from "antd";
import getInternalDump from "../../queries/getInternalDump";
import getRenewalCount from "../../queries/getRenewalCount";
import getInternalUsers from "../../queries/getInternalUsers";
import SiteLogs from "../deploymentDashboard/siteLogs";

import App from "../../App";
import axios from "axios";

function NonUsageDashboard(props) {
  const [renewalRecord, setrenewalRecord] = useState([]);
  const [allrenewalRecord, setallrenewalRecord] = useState([]);
  const [betweenDates, setbetweenDates] = useState([]);
  const [InternalUserData, setInternalUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setfilterloading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [siteData, setsiteData] = useState(new Object());
  const [supportAccM, setsupportAccM] = useState(undefined);
  const [salesPerson, setsalesPerson] = useState(undefined);
  const [partnerType, setpartnerType] = useState(undefined);
  const [partnerName, setpartnerName] = useState(undefined);
  const [companyName, setcompanyName] = useState(undefined);
  useEffect(() => {
    setLoading(true);
    callPromise();
  }, [props]);

  async function callPromise() {
    const data = await getUnUsedSites();
    // console.log("data :::", data);
    setrenewalRecord(data);
    setallrenewalRecord(data);
    setLoading(false);

    const currentDate = moment();
    currentDate.set('date', 5);
    if (currentDate.isAfter(moment())) {
      currentDate.subtract(1, 'month'); // If the 5th is in the future, go back a month
    }
    const pastDate = currentDate.clone().subtract(10, "days");
    const startDate = moment(pastDate.format("DD-MM-YYYY"), "DD-MM-YYYY");
    const endDate = moment(currentDate.format("DD-MM-YYYY"), "DD-MM-YYYY");
    
    // Create an array to store the between dates
    let betweenDates = [];
    
    // Clone the start date for iteration
    let currentDate1 = startDate.clone();
    
    // Iterate through the dates and add them to the array
    while (currentDate1.isSameOrBefore(endDate, "day")) {
      betweenDates.push(currentDate1.format("DD-MM-YYYY"));
      currentDate1.add(1, "day");
    }
    betweenDates.reverse();
    console.log("betweenDates", betweenDates);
    let users = await getUsers();
    setbetweenDates(betweenDates);
    setInternalUserData(users);
  }

  const getUnUsedSites = () => {
    // console.log("variables",variables)
    return new Promise((resolve, reject) => {
      props.client
        .query({
          query: getInternalDump,
          variables: {
            typeValue: "unused",
            dumpDate: "unused",
          },
        })
        .then(({ data }) => {
          // console.log("getUnUsedSites::",data.getInternalDump.dumpData)
          resolve(JSON.parse(data.getInternalDump.dumpData));
        });
    });
  };
  const getUsers = () => {
    return new Promise((resolve, reject) => {
      props.client
        .query({
          query: getInternalUsers,
          // fetchPolicy: 'no-cache'
        })
        .then(({ data }) => {
          resolve(data.getInternalUsers);
        });
    });
  };
  const supportAccMChange = (value) => {
    setsupportAccM(value);
  };
  const salesPersonChange = (value) => {
    setsalesPerson(value);
  };
  const partnerTypeChange = (value) => {
    setpartnerType(value);
  };
  const partnerNameChange = (value) => {
    setpartnerName(value);
  };
  const companyNameChange = (e) => {
    setcompanyName(e.target.value);
  };
  const handleFilter = () => {
    let filterData = allrenewalRecord;
    let final = [];
    setfilterloading(true);
    filterData.map((ele) => {
      let companyNameSlug = ele.companyName.toLowerCase();
      if (supportAccM && ele.supportAccManager == supportAccM) {
        final.push(ele);
      }
      if (salesPerson && ele.salesPerson == salesPerson) {
        final.push(ele);
      }
      if ((partnerType && ele.partnerType == partnerType) || (partnerName && ele.channelPartner == partnerName)) {
        final.push(ele);
      }
      // if(partnerName && ele.channelPartner == partnerName){
      //     final.push(ele)
      // }
      if (companyName && companyNameSlug.includes(companyName.toLowerCase())) {
        final.push(ele);
      }
    });
    setrenewalRecord(final);
    setfilterloading(false);
  };
  const resetFilter = () => {
    setsupportAccM(undefined);
    setsalesPerson(undefined);
    setpartnerType(undefined);
    setpartnerName(undefined);
    setcompanyName(undefined);
    setrenewalRecord(allrenewalRecord);
  };
  const openNotesModal = (data) => {
    setvisible(true);
    setsiteData(data);
  };
  const closeNotesModal = () => {
    setvisible(false);
  };
  const columns = [
    {
      title: "Business Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 200,
      fixed: "left",
      // render:(data)=>{
      //   return(
      //     <span>{this.truncateString(data,40)}</span>
      //   )
      // }
    },
    {
      title: "Sales Person",
      dataIndex: "salesPerson",
      key: "salesPerson",
      width: 100,
      // render:(data)=>{
      //   return(
      //     <span>{this.truncateString(data,40)}</span>
      //   )
      // }
    },
    {
      title: "Partner Name",
      dataIndex: "channelPartner",
      key: "channelPartner",
      width: 100,
    },
    {
      title: "Support Act Manager",
      dataIndex: "supportAccManager",
      key: "supportAccManager",
      width: 100,
    },
    {
      title: "Plan Name",
      dataIndex: "planName",
      key: "planName",
      width: 100,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      sorter: (a, b) => {
        return a.createdAt - b.createdAt;
      },
      render: (data) => {
        return (
          <div>
            {data != null && moment.unix(data).format("DD-MM-YYYY")}
            {data == null && <span>--</span>}
          </div>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 100,
      sorter: (a, b) => {
        return a.endDate - b.endDate;
      },
    },
  ];
  betweenDates &&
    betweenDates.map((ele, i) => {
      let obj = {
        title: ele,
        // dataIndex: 'createdAt',
        key: ele,
        width: 75,
        align: "center",
        render: (data) => {
          if (data && data.ordersCount && data.ordersCount.length > 0) {
            let orderCount = null;
            data.ordersCount.map((val) => {
              if (val.date == ele) {
                orderCount = val.ordersCount;
              }
            });

            return {
              props: {
                style: { background: orderCount ? "#afdb8b" : "#f4bfc0" },
              },
              children: <span>{orderCount ? orderCount : "0"}</span>,
            };
          } else {
            return {
              props: {
                style: { background: "#f4bfc0" },
              },
              children: <span>{"0"}</span>,
            };
          }
        },
      };
      columns.push(obj);
    });
  let note = {
    title: "Notes",
    key: "notes",
    width: 80,
    fixed: "right",
    align: "center",
    render: (data) => {
      return <Icon type="form" onClick={() => openNotesModal(data)} />;
    },
  };
  columns.push(note);
  return (
    <App header={"Renewal Dashboard"}>
      <Row gutter={24}>
        <Col md={{ span: 4 }}>
          <Select
            optionFilterProp="children"
            style={{ width: "100%", marginRight: "10px" }}
            onChange={supportAccMChange}
            value={supportAccM}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            showSearch
            placeholder="Support Account Manager"
          >
            {InternalUserData && InternalUserData.length > 0
              ? InternalUserData.map((ele) => {
                  if (ele.userRole == "SUPPORT_ACCOUNT_MANAGER" || ele.userRole == "CUSTOMER_SUPPORT_LEAD") {
                    return (
                      <Select.Option key={ele.name} value={ele.name}>
                        {ele.name}
                      </Select.Option>
                    );
                  }
                })
              : ""}
          </Select>
        </Col>
        <Col md={{ span: 4 }}>
          <Select
            optionFilterProp="children"
            style={{ width: "100%", marginRight: "10px" }}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={salesPersonChange}
            value={salesPerson}
            allowClear
            showSearch
            placeholder="Sales Person"
          >
            {InternalUserData && InternalUserData.length > 0
              ? InternalUserData.map((ele) => {
                  if (ele.userRole == "SALES_PERSON") {
                    return (
                      <Select.Option key={ele.name} value={ele.name}>
                        {ele.name}
                      </Select.Option>
                    );
                  }
                })
              : ""}
          </Select>
        </Col>
        <Col md={{ span: 4 }}>
          <Select
            optionFilterProp="children"
            style={{ width: "100%", marginRight: "10px" }}
            onChange={partnerTypeChange}
            value={partnerType}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            showSearch
            placeholder="Partner Type"
          >
            <Select.Option key={"cp"} value={"CP"}>
              CP
            </Select.Option>
            <Select.Option key={"reseller"} value={"RESELLER"}>
              Reseller
            </Select.Option>
            <Select.Option key={"referral"} value={"REFERRAL"}>
              Referral
            </Select.Option>
            <Select.Option key={"stockist"} value={"STOCKIST"}>
              Stockist
            </Select.Option>
          </Select>
        </Col>
        <Col md={{ span: 4 }}>
          <Select
            optionFilterProp="children"
            style={{ width: "100%", marginRight: "10px" }}
            onChange={partnerNameChange}
            value={partnerName}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            showSearch
            placeholder="Partner Name"
          >
            {InternalUserData && InternalUserData.length > 0
              ? InternalUserData.map((ele) => {
                  if (partnerType == "CP") {
                    if (ele.companyName) {
                      return (
                        <Select.Option key={ele.companyName} value={ele.companyName}>
                          {ele.companyName}
                        </Select.Option>
                      );
                    }
                  } else {
                    return (
                      <Select.Option key={ele.name} value={ele.name}>
                        {ele.name}
                      </Select.Option>
                    );
                  }
                })
              : ""}
          </Select>
        </Col>
        <Col md={{ span: 4 }}>
          <Input placeholder="Business Name" value={companyName} onChange={companyNameChange} style={{ width: "100%", marginRight: "10px" }} />
        </Col>
        <Col md={{ span: 4 }}>
          <Button type="danger" style={{ float: "right", marginBottom: 16, cursor: "pointer", marginLeft: "10px", width: 60 }} onClick={resetFilter}>
            Reset
          </Button>

          <Button
            type="primary"
            style={{ float: "right", marginBottom: 16, cursor: "pointer", width: 70 }}
            loading={filterloading}
            onClick={handleFilter}
          >
            Search
          </Button>
        </Col>
      </Row>

      <Table
        className="sitetable"
        columns={columns}
        bordered
        dataSource={renewalRecord}
        loading={loading}
        size="middle"
        rowKey="id"
        scroll={{ x: "110%" }}
        pagination={{ pageSize: 100 }}
      />
      <Modal title={"Notes"} visible={visible} onCancel={closeNotesModal} footer={null} destroyOnClose={true} width={1200}>
        <SiteLogs siteDetails={siteData} />
      </Modal>
    </App>
  );
}
export default withApollo(NonUsageDashboard);
