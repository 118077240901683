import React, { Component } from 'react';
import './App.css';

import { Col, Icon, Layout, Menu, Row, Tooltip } from 'antd';

import  { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import logo from '../src/images/recaho.png';
import { withRouter } from "react-router";
import MyProfile from './components/profile/myProfile';
import {UserContext} from "./components/context/userConfigContext";
const WithUserContext = (Component) => {
  return (props) => {
    return <UserContext.Consumer>
          {permissions => {
         return <Component {...props} userData={permissions} />
        }
  }
      </UserContext.Consumer>
  }
}
const {
  Header, Content, Sider,
} = Layout;
const { SubMenu } = Menu;
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activemenuKey: 0,
      defaultOpen: "",
    };
  }

  handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        window.location=window.location.origin
        // window.location.reload(true)
      })
      .catch(err => console.log(err));
  };



  static showMenuSelected(url) {
    const pathArr = url.split("/").filter(pathVal => pathVal != "");
    const pathName = pathArr[0];
    let activeKey = "0";
    let defaultOpen = "";

    switch (pathName) {
        case undefined:
        activeKey = "1";
        break;
        case "create-site":
        activeKey = "1";
        break;
        case "service-typelist":
        activeKey = "1";
        break;
        case "add-serviceType":
        activeKey = "1";
        break;
        case "edit-site":
        activeKey = "1";
        break;
        case "add-extra-service":
        activeKey = "1";
        break;
        case "site-pages":
        activeKey = "2";
        break;
        case 'create-site-pages':
        activeKey = "2";
        break;
        case 'edit-site-pages':
        activeKey = "2";
        break;
        case 'customer-list':
        activeKey = "3";
        break;
        case 'email-Customer':
        activeKey = "3";
        break;
        case 'global-template':
        activeKey = "global-template";
        defaultOpen = "sub2";
        break;
        case 'addg-templates':
        activeKey = "global-template";
        defaultOpen = "sub2";
        break;
        case 'editg-templates':
        activeKey = "global-template";
        defaultOpen = "sub2";
        break;
        case 'service-list':
        activeKey = "service-list";
        break;
        case 'platform-servicelist':
        activeKey = "platform-servicelist";
        break;
        case 'create-platform':
        activeKey = "platform-servicelist";
        break;
        case 'edit-platform':
        activeKey = "platform-servicelist";
        break;
        case 'subscription-plan':
        activeKey = "subscription-plan";
        break;
        case 'create-subscription':
        activeKey = "subscription-plan";
        break;
        case 'edit-subscription':
        activeKey = "subscription-plan";
        break;
        case 'oulets-index':
        activeKey = "oulets-index";
        break;
        case 'create-oulets':
        activeKey = "oulets-index";
        break;
        case 'manage-user':
        activeKey = "manage-user";
        break;
        case 'deployment-dashboard':
        activeKey = "deployment-dashboard";
        break;
        case 'renewal-dashboard':
        activeKey = "renewal-dashboard";
        break;
        case 'create-user':
        activeKey = "manage-user";
        break;
        case 'template-list':
        activeKey = "template-list";
        defaultOpen = "sub1";
        break;
        case 'editm-templates':
        activeKey = "template-list";
        defaultOpen = "sub1";
        break;
        case 'addm-templates':
        activeKey = "template-list";
        defaultOpen = "sub1";
        break;
        case 'campaign-history':
        activeKey = "campaign-history";
        defaultOpen = "sub1";
        break;
        case 'marketing-index':
        activeKey = "marketing-index";
        defaultOpen = "sub1";
        break;
        case 'create-service':
        activeKey = "service-list";
        break;
        case 'create-extra-service':
        activeKey = "service-list";
        break;
        case 'my-profile':
          activeKey = "user-profile";
        break;
        case 'select-cp':
          activeKey = "cp-dashboard";
        break;
        case 'my-dashboard':
          activeKey = "My-Dashboard";
          defaultOpen = "sub1channelPartner";
        break;
        default:
        activeKey = "0";
    }
    return {activeKey,defaultOpen}
  }
  static getDerivedStateFromProps(nextProps, nextState) {
    const getActiveMenuId = App.showMenuSelected(
      nextProps.match.url,
    );
    return {
      activemenuKey: getActiveMenuId.activeKey,
      defaultOpen: getActiveMenuId.defaultOpen,
    };
  }

  render() {
    const { children } = this.props;
    const { activemenuKey } = this.state;
   
    return ( 
      <Layout style={{ height: "100vh", background: "#001529"}}>
      <Sider
        style={{height:'100vh'}}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => { console.log(broken); }}
        onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
      >
         <div
            className="logo"
            style={{
              height: "32px",
              background: "#001529",
              margin: "16px"
            }}>
            
            <img src={logo} alt='img' className="header-logo" style={{width:"170px"}} />
          </div> 
        <Menu theme="dark" mode="inline" selectedKeys={[activemenuKey]} defaultOpenKeys={[this.state.defaultOpen]}>
          <Menu.Item key="1">
              <Link to="/">
                <Icon type="user" />
                <span className="nav-text">{this.props.userData && this.props.userData.userRole && this.props.userData.userRole == "CP" ? "My Dashboard":"Site"}</span>
              </Link>
          </Menu.Item>
          { this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole == "CP" &&<Menu.Item key="user-profile">
              <Link to="/my-profile">
                <Icon type="user" />
                <span className="nav-text">My Profile</span>
              </Link>
          </Menu.Item>}

          { this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == "SALES_PERSON" || this.props.userData.userRole ==  'SITE_CREATOR') && <Menu.Item key="cp-dashboard">
              <Link to="/select-cp">
                <Icon type="dashboard" />
                <span className="nav-text">CP Dashboard</span>
              </Link>
          </Menu.Item>}

         {/* { this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole == "CP" && <SubMenu
              key="sub1channelPartner"
              title={
                <span>
                  <Icon type="appstore" 
                  // style={{ fontSize: "20px" }} 
                  />
                  <span style={{ color: "#D3D3D3" }}>
                  Channel Partner
                  </span>
                </span>
              }>
                <Menu.Item key="My-Dashboard">
                  <Link to="/my-dashboard">
                    <Icon type="user" />
                      <span className="nav-text">My Dashboard</span>
                  </Link>
                </Menu.Item>
          </SubMenu>} */}

          {/* <Menu.Item key="oulets-index">
              <Link to="/oulets-index">
                <Icon type="shop" />
                <span className="nav-text">Manage Outlets</span>
              </Link>
          </Menu.Item> */}
          {this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == 'SITE_CREATOR')&&
          <Menu.Item key="manage-user">
              <Link to="/manage-user">
                <Icon type="user" />
                <span className="nav-text">Manage Users</span>
              </Link>
          </Menu.Item>
          }
          {this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole == 'CUSTOMER_SUPPORT_LEAD' || this.props.userData.userRole == 'SUPPORT_EXECUTIVE' || this.props.userData.userRole ==  'SITE_CREATOR' || this.props.userData.userRole == 'SUPPORT_ACCOUNT_MANAGER' ||this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == 'SALES_PERSON' )&&
          <Menu.Item key="deployment-dashboard">
              <Link to="/deployment-dashboard">
                <Icon type="dashboard" />
                <span className="nav-text">Onboarding Dashboard</span>
              </Link>
          </Menu.Item>
          }
          {this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole ==  'SITE_CREATOR' || this.props.userData.userRole == 'ADMIN' ) &&
           <Menu.Item key="renewal-dashboard">
            <Link to="/renewal-dashboard">
              <Icon type="dashboard" />
              <span className="nav-text">Renewal Dashboard</span>
            </Link>
          </Menu.Item>
          }
          {this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole ==  'CUSTOMER_SUPPORT_LEAD' || this.props.userData.userRole == 'ADMIN') &&
           <Menu.Item key="nonusage-dashboard">
            <Link to="/nonusage-dashboard">
              <Icon type="dashboard" />
              <span className="nav-text">Non Usage Dashboard</span>
            </Link>
          </Menu.Item>
          }
          { this.props && this.props.userData && this.props.userData.userRole && (this.props.userData.userRole == 'ADMIN' || this.props.userData.userRole == "SALES_PERSON" || this.props.userData.userRole ==  'SITE_CREATOR') && <Menu.Item key="outstanding-dashboard">
              <Link to="/outstanding-dashboard">
                <Icon type="dashboard" />
                <span className="nav-text">Outstanding Dashboard</span>
              </Link>
          </Menu.Item>}
        {this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole != "CP" && <Menu.Item key="2">
              <Link to="/site-pages">
                  <Icon type="file-text" />
                  <span className="nav-text">Pages</span>
              </Link>
          </Menu.Item>}
          {this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole != "CP" &&  <Menu.Item key="global-template">
            
                  <Link to="/global-template">
                    <Icon type="file" />
                      <span className="nav-text">Global Templates</span>
                  </Link>
           </Menu.Item>}

           {this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole == 'ADMIN' &&
          <Menu.Item key="service-list">
              <Link to="/service-list">
                  <Icon type="file-text" />
                  <span className="nav-text">Master Service List</span>
              </Link>
          </Menu.Item>
          }
          {this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole == 'ADMIN' &&
          <Menu.Item key="platform-servicelist">
              <Link to="/platform-servicelist">
                  <Icon type="file-text" />
                  <span className="nav-text">Platform Service</span>
              </Link>
          </Menu.Item>
          }
          {this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole == 'ADMIN' &&
          <Menu.Item key="subscription-plan">
              <Link to="/subscription-plan">
                  <Icon type="file-text" />
                  <span className="nav-text">Subscription Plan</span>
              </Link>
          </Menu.Item>
          }
          {/* <Menu.Item key="3">
              <Link to="/customer-list">
                <Icon type="team" />
                  <span className="nav-text">Customer List</span>
              </Link>
          </Menu.Item> */}

           {this.props && this.props.userData && this.props.userData.userRole && this.props.userData.userRole != "CP" && <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="appstore" 
                  // style={{ fontSize: "20px" }} 
                  />
                  <span style={{ color: "#D3D3D3" }}>
                  Campaigns
                  </span>
                </span>
              }>
                <Menu.Item key="marketing-index">
                  <Link to="/marketing-index">
                    <Icon type="user" />
                      <span className="nav-text">Run Campaign</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="template-list">
                  <Link to="/template-list">
                    <Icon type="file" />
                      <span className="nav-text">Templates</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="campaign-history">
                  <Link to="/campaign-history">
                    <Icon type="file" />
                      <span className="nav-text">Campaign History</span>
                  </Link>
                </Menu.Item>

          </SubMenu>}
        </Menu>
      </Sider>
      <Layout style={{ height: "100vh" }}>
        <Header style={{ background: '#fff', padding: 0 }}>
        <Row>
          <Col span={12}>
         <h2 style={{marginLeft:'20px'}}>{this.props.header}</h2>
          </Col>
          <Col>
          <div style={{display:"flex",justifyContent:"end"}}>
           {this.props.userData.userRole == "CP" && <><>
            <h2 style={{marginRight:'30px' }}>{this.props.userData && this.props.userData.companyName &&this.props.userData.companyName}</h2>
            </>
          <div>
            <MyProfile {...this.props}/>
          </div> </> }
          <div style={{cursor:"pointer", padding:"10px",float:"right"}} onClick={this.handleSignOut}>
          <Tooltip placement="left"  title="Logout">
           <Icon type="logout"   style={{ fontSize: '30px', color: '#08c'}}/>
          </Tooltip>
           </div>
           </div>
          </Col>
        </Row>
        
        </Header>
        <Content className="content">
           {children}
        </Content>
      </Layout>
    </Layout>
    );
  }
}

export default WithUserContext(withRouter(App));
