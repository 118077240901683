import React from 'react'
import { Button,  Icon, Input, Row,  Table,Modal,Form,message,Select,Popconfirm,Col,Tag } from 'antd';
import { withApollo } from 'react-apollo';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';
import moment from 'moment'
import getSiteWalletById from '../../queries/getSiteWalletById'
import App from '../../App'
// import getSitesAdmin from '../../queries/getSitesAdmin'
import getBasicSiteSetting from '../../queries/getBasicSiteSetting'
import getSitesBySiteTypeAndOption from '../../queries/getSitesBySiteTypeAndOption'
import getBasicSiteSettingBySiteAlias from '../../queries/getBasicSiteSettingBySiteAlias'
import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId'
import siteWalletRechargeByAdmin from '../../mutation/siteWalletRechargeByAdmin'   
import downloadInactiveSites from '../../mutation/downloadInactiveSites'   
import downloadAllSites from '../../mutation/downloadAllSites'   
import deleteSite from '../../mutation/deleteSite'
import resetSite from '../../mutation/resetSite'
import updateSiteVersion from '../../mutation/updateSiteVersion'  
import UserOperation  from '../../utils/userOperations'
import {gerenricQueryFunction} from "../../utils/gerenricQueryFunction"
import {getStateByCountry,getcitiesByStateAndCountry} from "../../utils/getStateByCountry"
import getInternalUsers from '../../queries/getInternalUsers'
import PaymentDetails from './PaymentDetails'
import SiteLogs from '../deploymentDashboard/siteLogs'
import SiteActionEvent from '../../funComponent/SiteActionEvent'
import {returnSymbol} from '../../utils/returnSymbol'



import "./siteList CSS/siteList.css"
const { Option } = Select;
const country = [
  { value: 'IND', label: 'India',key:'IN' },
  { value: 'AUS', label: 'Australia',key:'AU' },
  { value: 'USA', label: 'USA',key: 'US' },
  { value: 'UK', label: 'UK',key:'GB' },
  { value: 'UAE', label: 'UAE',key:'AE' },
  { value: 'ZMW', label: 'Zambia',key:'ZM' },
  { value: 'SAU', label: 'Saudi Arabia',key:'SA' },
  { value: 'QAT', label: 'Qatar',key:'QA' },
  { value: 'BHR', label: 'Bahrain',key:'BH' },
  { value: 'SGP', label: 'Singapore',key:'SG' },
  { value: 'NPL', label: 'Nepal',key:'NP' },
  { value: 'KWT', label: 'Kuwait',key:'KW' },
  { value: 'OMN', label: 'Oman',key:'OM' },
  { value: 'KEN', label: 'Kenya',key:'KE' },
  { value: 'MDV', label: 'Maldives',key:'MV' },
  { value: 'IDN', label: 'Indonesia',key:'ID' },
  { value: 'THA', label: 'Thailand',key:'TH' },
  { value: 'MDV', label: 'Malaysia',key:'MY' },
]
var PLATFORM = [
  // { value: 'ENQUIRY', label: 'Enquiry' },
  { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
  { value: 'BOOKING_SALOON', label: 'Saloon' },
  // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
  // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
  { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
  { value: 'LAUNDRY', label: 'Laundry' },
  { value: 'EVENT', label: 'Event' },
  { value: 'VILLA_RENTAL', label: 'Vill Rental' },
  { value: 'SERVICES', label: 'Services' },
  { value: 'CAR_RENTAL', label: 'Car Rental' },
  { value: 'CONSTRUCTION', label: 'Construction' },
  { value: 'GROCERY', label: 'Grocery' },
  { value: 'GENERAL', label: 'General' },
  { value: 'LAWYER', label: 'Lawyer' },
  { value: 'PET_GROOMERS', label: 'Pet Groomers' },
  { value: 'REPAIR_SERVICES', label: 'Repair Services' },
  { value: 'SPA', label: 'Spa' },
  { value: 'GYM', label: 'Gym' },
  { value: 'MANUFACTURER', label: 'Manufacturer' },
  { value: 'JEWELLERY', label: 'Jewellery' },
  { value: 'MARRIAGE', label: 'Marriage' },
  { value: 'AUTO_SERVICES', label: 'Auto Services' },
  { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
  { value: 'DOCTOR', label: 'Doctor' },
  { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
  { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
  { value: 'WAREHOUSE', label: 'Warehouse' },
].sort((a,b)=>a.label.localeCompare(b.label))

const type = [
  { key: '1', value: 'SIMPLE_B2C', label: 'e-Commerce' },
  { key: '2', value: 'MARKETING', label: 'Marketing Site' },
  
  // { key: '4', value: 'JEWEL_EXPO_BASIC', label: 'Jewel Expo Basic' },
  // { key: '8', value: 'JEWEL_EXPO_PREMIUM', label: 'Jewel Expo Premium' },
  // { key: '6', value: 'B2B_ECOM', label: 'B2B e-Commerce' },
  { key: '7', value: 'B2C_MARKET_PLACE', label: 'B2C Market Place' },
  // { key: '8', value: 'B2B2C_ECOM', label: 'B2B2C Ecommerce' },
  { key: '9', value: 'O2OCommerce', label: 'O2O Commerce' }
]

const FormItem = Form.Item;
class SiteList extends React.Component{

    constructor(props){
      super(props)
      this.state = {
        searchText:'',
        visibleM: false,
        historyModal: false,
        paymentLoading: false,
        donloadLoding: false,
        donloadAllLoding: false,
        show: false,
        show1: false,
        loading:false,
        modalData: {},
        siteData: [],
        masterSite: [],
        currentB: '',
        siteType: 'O2OCommerce',
        siteOption: 'BOOKING_RESTAURANTS',
        allPlan: [],
        allSiteData: [],
        planData: [],
        planNames: [],
        InternalUserData: [],
        partnerType: undefined,
        siteStatus: undefined,
        partnerName: undefined,
        salesPerson: undefined,
        supportAccM: undefined,
        paymentFilter: undefined,
        custName: undefined,
        custMobile: undefined,
        companyName: undefined,
        selectedCountry: undefined,
        selectedState: undefined,
        selectedCity: undefined,
        selectedCity1: undefined,
        allState:[],
        allCities:[],
        visiblePayment: false,
        siteDetails: {},
        filterLoadimg: false


      }
    }
  componentDidMount(){
    this.enterLoading()
    this.callPromise()
    this.getMasterPlan()
    this.getUsers()
    // this.props.client.query({
    //   query: getSitesAdmin,
    //   fetchPolicy: 'no-cache'
    // })
    //   .then(({ data }) => {
    //     // console.log('dataa', data)
    //     let temp = data.getSitesAdmin
    //    let newArr = temp.filter((x)=> x.isGroupAdmin)
    //     this.setState({
    //       siteData: data.getSitesAdmin,
    //       masterSite: newArr,
    //       loading: false
    //     })
    //   })
  }
  async callPromise () {
    const [ response] = await Promise.all([
      gerenricQueryFunction(getSitesBySiteTypeAndOption, this.props.client, {siteType: this.state.siteType, siteOption: this.state.siteOption,limit: 900}, "network-only", "getSitesBySiteTypeAndOption"),
    ])

    // console.log("response ::",response.data)
    let temp = response.data
    let newArr = temp.filter((x)=> x.isGroupAdmin)
    let arr = []
    temp.map((ele)=>{
      let index =null 
      if(ele.siteRecurringConfig && ele.siteRecurringConfig.siteServices){
        index = ele.siteRecurringConfig.siteServices.findIndex((x)=> x.serviceName =='SITE')
      }
      let mobile = ele.basicSiteSetting && ele.basicSiteSetting.contactNumber ? ele.basicSiteSetting.contactNumber : ''
      let email = ele.basicSiteSetting && ele.basicSiteSetting.contactEmail ? ele.basicSiteSetting.contactEmail : ''
      let obj={...ele,mobile:mobile,
      contactEmail: email,
      custNameSlug: ele.custName.toLowerCase(),
      companyNameSlug: ele.companyName.toLowerCase(),
      city:ele.basicSiteSetting && ele.basicSiteSetting.footerAddress && ele.basicSiteSetting.footerAddress.city ? ele.basicSiteSetting.footerAddress.city : '',
      citySlug:ele.basicSiteSetting && ele.basicSiteSetting.footerAddress && ele.basicSiteSetting.footerAddress.city ? ele.basicSiteSetting.footerAddress.city.toLowerCase() : '',
      stateSlug:ele.basicSiteSetting && ele.basicSiteSetting.footerAddress && ele.basicSiteSetting.footerAddress.state ? ele.basicSiteSetting.footerAddress.state.toLowerCase() : '',
      state:ele.basicSiteSetting && ele.basicSiteSetting.footerAddress && ele.basicSiteSetting.footerAddress.state ? ele.basicSiteSetting.footerAddress.state : '',
      country:ele.basicSiteSetting && ele.basicSiteSetting.footerAddress && ele.basicSiteSetting.footerAddress.country ? ele.basicSiteSetting.footerAddress.country : '',
      planName: ele.planDetails && ele.planDetails.length > 0 ? ele.planDetails.map((ele)=>{ return ele.planName}) : [],
      siteStatus: index != null && index != -1 ? ele.siteRecurringConfig.siteServices[index].status == 'INACTIVE' ? false : true : false,
      endDate: index != null && index != -1 ? ele.siteRecurringConfig.siteServices[index].endDate: undefined,
      startDate: index != null && index != -1 ? ele.siteRecurringConfig.siteServices[index].startDate: undefined,
      subscriptionPrice: index != null && index != -1 ? ele.siteRecurringConfig.siteServices[index].charges/100: undefined,
    }
      arr.push(obj)
    })
    // arr.map((ele)=>{
    //   if(ele.subscriptionPrice == 0){
    // console.log("ele ",ele)
    //   }
    // })

     this.setState({
       siteData: arr,
       allSiteData: arr,
       masterSite: newArr,
       loading: false,
       filterLoadimg: false
     })
  }
    enterLoading=()=>{
      this.setState({
        loading: true
      })
    }
    getMasterPlan=()=>{
      this.props.client.query({
          query: getSubscriptionPlansBySiteId,
          variables: {
              siteId:'master',
          },
          fetchPolicy: 'network-only',
      })
          .then(({ data }) => {
              // console.log("getSubscriptionPlansBySiteId ::",data)
              if(data && data.getSubscriptionPlansBySiteId){
                  this.siteOptionPlanFilter(data.getSubscriptionPlansBySiteId,this.state.siteType,this.state.siteOption)
                  this.setState({
                      allPlan: data.getSubscriptionPlansBySiteId
                  })
              }
          })
  }
  getUsers=()=>{
    this.props.client.query({
        query: getInternalUsers,
        fetchPolicy: 'cache-first'
    })
        .then(({ data }) => {
          // console.log("data::",data)
            this.setState({
                InternalUserData: data.getInternalUsers,
                // loading: false
            })
        })
}
    siteOptionPlanFilter=(allPlan,siteType,platform)=>{
      let sort = allPlan.filter( function(hero){
          return (platform ? (hero.siteType == siteType && hero.platform == platform) : hero.siteType == siteType)
        })
        let fData = sort.sort((a,b)=>a.planName.localeCompare(b.planName))
        this.setState({
          planData: fData
        })
  }
    handleSearch = (selectedKeys, confirm) => {
      confirm();
      this.setState({ searchText: selectedKeys[0] });
    }

    handleReset = (clearFilters) => {
      clearFilters();
      this.setState({ searchText: '' });
    }

    getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node; }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
    })

    getBasicSiteSettingBySiteAlias = async (domain) => {
      const data = await this.props.client.query({
        query: getBasicSiteSettingBySiteAlias,
        variables: {
          domain: domain
        },
        fetchPolicy: 'no-cache'
      })
      return data.data.getBasicSiteSettingBySite.curr;
    }

    modalChange= async (data)=>{
      let userPermissions = UserOperation('site','wallet_recharge',this.props.userData)
      if(userPermissions){
      this.getRecharge(data.siteId)
      data.curr = await this.getBasicSiteSettingBySiteAlias(data.fqdn);
      // console.log("Data",data)
      this.setState({
        visibleM: true,
        modalData:data
      })
    }else{
      message.error(`Dear User, You don't have access to this page.`)
    }
    }
    siteDelete=(record)=>{
      // console.log("record ::",record)
      this.enterLoading()
      this.props.client.mutate({
        mutation: deleteSite,
        variables: {
          domain: record.fqdn,
        }
    }).then(({ data }) => {
      message.success('Site Delete Successfully')
          let arr = this.state.siteData
          let i = arr.findIndex(x => x.fqdn == record.fqdn)
          arr.splice(i,1)
          this.createLogs("Delete Site",record.siteId)
          this.setState({
            siteData:arr,
            loading: false
          })
      })
    }
    siteReset=(record)=>{
      // console.log("record ::",record)
      this.enterLoading()
      this.props.client.mutate({
        mutation: resetSite,
        variables: {
          domain: record.fqdn,
          siteId: record.siteId
        }
    }).then(({ data }) => {
          message.success('Site Reset Successfully')
          this.createLogs("Reset Site Data",record.siteId)
          this.setState({
            loading: false
          })
      })
    }
    createLogs=async(name,siteId)=>{
      let obj = {
          siteId: siteId,
          eventName: name,
          eventType: 'MANUAL',
          // reason: this.state.selectedReason,
          note: this.state.notes
      }
      let resp = await SiteActionEvent(this.props,obj)
      if(resp){
        this.setState({
          notes: undefined
        })
      }
    }
    SwitchonChange=(flag,record)=>{
      // return
      this.enterLoading()
      this.props.client.mutate({
        mutation: updateSiteVersion,
        variables: {
          domain: record.fqdn,
          appVersion: flag ? 'v2' : 'v1'
        }
    }).then(({ data }) => {
        if(data.updateSiteVersion){
          let arr = this.state.siteData
          let i = arr.findIndex(x => x.fqdn == record.fqdn)
          arr[i].appVersion = flag ? 'v2' : 'v1'
          this.setState({
            siteData:arr,
            loading: false
          })
        }
      })
    }

    handleOk=(e)=>{
      // console.log("on ok")  
      e.preventDefault();
        this.props.form.validateFields((err, values) => {
          // console.log("values",values,this.state.modalData)
          // return
            if(!err){
              this.setState({
                paymentLoading: true
              })
      this.props.client.mutate({
        mutation: siteWalletRechargeByAdmin,
        variables: {
          siteId: this.state.modalData.siteId,
          amt: parseInt(values.amount*100),
          currency: this.state.modalData.curr,
          rechargeBy: "Admin",
          note: values.note ? values.note : undefined,
          paymentState: "PAID_OFFLINE"
        }
    }).then(({ data }) => {
        // console.log("data response", data)
        message.success("Recharge successfully");
        this.props.form.resetFields(['amount'])
        this.props.form.resetFields(['note'])
        this.getRecharge(this.state.modalData.siteId)
        this.setState({
          paymentLoading: false
        })
    })
  }
  })
    }

    handleCancel=()=>{
      this.setState({
        visibleM: false
    })
    }
    handleCancelhistoryModal=()=>{
      this.setState({
        historyModal: false,
        siteDetails: {}
    })
    }
    handleCancelPayment=()=>{
      this.setState({
        visiblePayment: false
    })
    }
    getRecharge=(siteId)=>{
      this.props.client.query({
        query: getSiteWalletById,
        variables: {
            siteId: siteId
        },
        fetchPolicy: 'no-cache'
      })
        .then(({ data }) => {
          // console.log('dataa', data)
          this.setState({
            currentB: data.getSiteWalletById,
            loading: false
          })
        })
    }
    rAmount=(e)=>{
      // console.log("e",e.target.value)
    }
    downloadInactive =()=>{
      this.setState({
        donloadLoding: true
      })
      console.log("Click::")
      this.props.client.mutate({
        mutation: downloadInactiveSites,
    }).then(({ data }) => {
        console.log("data response", data)
        this.setState({
          show: true,
          donloadLoding: false
        },()=>{
          document.getElementById('down').click()
        })

    })
    }
    downloadAllSites =()=>{
      this.setState({
        donloadAllLoding: true
      })
      console.log("Click::")
      this.props.client.mutate({
        mutation: downloadAllSites,
    }).then(({ data }) => {
        console.log("data response", data)
        this.setState({
          show1: true,
          donloadAllLoding: false
        },()=>{
          document.getElementById('down1').click()
        })

    })
    }

    getBasicSiteSetting = async (domain) => {
      const data = await this.props.client.query({
        query: getBasicSiteSetting,
        variables: {
          domain: domain
        },
        fetchPolicy: 'no-cache'
      })
      return data.data.getBasicSiteSettingBySite;
      // this.props.client.query({
      //   query: getBasicSiteSetting,
      //   variables: {
      //     domain: domain
      //   },
      //   fetchPolicy: 'no-cache'
      // })
      //   .then(({ data }) => {
      //     console.log('dataaaaa', data)
      //     return data.getBasicSiteSettingBySite;
      //     this.setState({
      //       siteData: data.getSitesAdmin,
      //       loading: false
      //     })
      //   })
    }

    editClick = async (data) => {
      let basicSiteData;
      try{
        basicSiteData = await this.getBasicSiteSetting(data.fqdn);
      }
      catch(error){
        console.log("error ::", error)
      }
      // console.log("basicSiteData ::", basicSiteData)
      // console.log("data ::", data)
      const combinedData = {...basicSiteData, ...data};
      console.log("combinedData ::", combinedData)
      // return
      this.props.history.push({
        pathname:'/edit-site', 
        state:{siteData:combinedData,masterData: this.state.masterSite}
      });
    }
    paymentDetails = async (data) => {
      let userPermissions = UserOperation('editSite','wallet_settings',this.props.userData)
      if(userPermissions){
      let basicSiteData;
      try{
        basicSiteData = await this.getBasicSiteSetting(data.fqdn);
      }
      catch(error){
        console.log("error ::", error)
      }
      const combinedData = {...basicSiteData, ...data};
      // console.log("combinedData ::", combinedData)
      // return
      // this.props.history.push({
      //   pathname:'/edit-site', 
      //   state:{siteData:combinedData,masterData: this.state.masterSite}
      // });
      this.setState({
        siteDetails: combinedData,
        visiblePayment: true
      })
    }else{
      message.error(`Dear User, You don't have access to this page.`)
    }
  }
  siteLogs=(record)=>{
    this.setState({
      siteDetails: record,
      historyModal: true,
    })
  }
    // handleSiteTypeChange=(value)=>{
    //   this.setState({
    //     siteType: value
    //   })
    // }
    enterfilterLoading=()=>{
      this.setState({
      filterLoadimg: true
    })
    }
    handleSiteTypeChange = (value) => {
      // console.log("Value..", value);

      if (value === 'SIMPLE_B2C' || value === "B2C_MARKET_PLACE" || value === "B2B2C_ECOM") {
          // console.log("Value.. in If", value);
          PLATFORM = [
              { value: 'COMMERCE', label: 'e-Commerce' },
              { value: 'ENQUIRY', label: 'Enquiry' },
              { value: 'ECOM_CATALOGUE', label: 'Catalogue' },
              { value: 'B2B_GIFTING', label: 'Gifting' },
          ]
          // this.enterLoading()
          // this.enterfilterLoading()
          // this.callPromise()
          this.setState({
            siteType: value,
            siteOption: undefined
          })
      } else if(value === 'MARKETING') {
        // this.enterLoading()
        // this.enterfilterLoading()
        // this.callPromise()
          PLATFORM = [
              { value: 'GENERAL', label: 'General' }
          ]
          this.setState({
            siteType: value,
            siteOption: undefined
          })
      }
     
      if (value === 'O2OCommerce') {
          PLATFORM = [
                          // { value: 'ENQUIRY', label: 'Enquiry' },
                          { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
                          { value: 'BOOKING_SALOON', label: 'Saloon' },
                          // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
                          // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
                          { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
                          { value: 'LAUNDRY', label: 'Laundry' },
                          { value: 'EVENT', label: 'Event' },
                          { value: 'VILLA_RENTAL', label: 'Vill Rental' },
                          { value: 'SERVICES', label: 'Services' },
                          { value: 'CAR_RENTAL', label: 'Car Rental' },
                          { value: 'CONSTRUCTION', label: 'Construction' },
                          { value: 'GROCERY', label: 'Grocery' },
                          { value: 'GENERAL', label: 'General' },
                          { value: 'LAWYER', label: 'Lawyer' },
                          { value: 'PET_GROOMERS', label: 'Pet Groomers' },
                          { value: 'REPAIR_SERVICES', label: 'Repair Services' },
                          { value: 'SPA', label: 'Spa' },
                          { value: 'GYM', label: 'Gym' },
                          { value: 'MANUFACTURER', label: 'Manufacturer' },
                          { value: 'JEWELLERY', label: 'Jewellery' },
                          { value: 'MARRIAGE', label: 'Marriage' },
                          { value: 'AUTO_SERVICES', label: 'Auto Services' },
                          { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
                          { value: 'DOCTOR', label: 'Doctor' },
                          { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
                          { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
                          { value: 'WAREHOUSE', label: 'Warehouse' }
          ].sort((a,b)=>a.label.localeCompare(b.label))
          // this.enterLoading()
          // this.enterfilterLoading()
          this.setState({
            siteType: value,
            siteOption: undefined,
          })
      }
     
  }
    handleSiteOption = (value) => {
      this.enterLoading()
      this.enterfilterLoading()
      this.setState({
        siteOption: value
      },()=>{
        this.siteOptionPlanFilter(this.state.allPlan,this.state.siteType,value)
        this.callPromise()

      })
    }
    onChangePlan=(value)=>{
      this.setState({
        planNames: value
      })
    }
    handleSiteStatus=(value)=>{
      this.setState({
        siteStatus:value
      })
    }
    handleFilter=()=>{
      this.enterfilterLoading()
      this.enterLoading()
      let siteData = this.state.allSiteData
      let filterData = []
      if(this.state.planNames && this.state.planNames.length > 0){
            for(let i=0;i<this.state.planNames.length;i++){
              for(let j=0;j<siteData.length;j++){
                if(siteData[j].planName && siteData[j].planName.includes(this.state.planNames[i])){
                  filterData.push(siteData[j])
                }
              }
            }
        
      }else{
        filterData = siteData
      }
      if(this.state.siteStatus != undefined){
       let aar =  filterData.filter((x)=>x.siteStatus == this.state.siteStatus)
       filterData = aar 
      }
      if(this.state.partnerType){
        let aar =  filterData.filter((x)=>x.partnerType == this.state.partnerType)
        filterData = aar 
      }
      if(this.state.partnerName){
        let aar =  filterData.filter((x)=>x.channelPartner == this.state.partnerName)
        filterData = aar 
      }
      if(this.state.salesPerson){
        let aar =  filterData.filter((x)=>x.salesPerson == this.state.salesPerson)
        filterData = aar 
      }
      if(this.state.supportAccM){
        let aar =  filterData.filter((x)=>x.supportAccManager == this.state.supportAccM)
        filterData = aar 
      }
      if(this.state.paymentFilter){
          if(this.state.paymentFilter == 'EXPIRING_30DAYS'){
            const currentDate = moment();
            const next30Days = moment().add(30, 'days');
            let arr = []
            for(let i=0;i<filterData.length; i++){
              let obj={}
              const expirationDate = moment(filterData[i].endDate,'YYYY-MM-DD')
              const isExpiringWithin30Days =expirationDate.isBetween(currentDate, next30Days)
                console.log("isExpiringWithin30Days::",isExpiringWithin30Days)
                if(isExpiringWithin30Days){
                    arr.push(filterData[i])
                }
            }
            filterData = arr
          }
          if(this.state.paymentFilter =='FREE_SITE'){
            let aar =  filterData.filter((x)=>x.subscriptionPrice ==0)
              filterData=aar
          }
      }
      if(this.state.custName){
        let aar =  filterData.filter((x)=>x.custNameSlug.includes(this.state.custName.toLowerCase()))
              filterData=aar
      }
      if(this.state.custMobile){
        let aar =  filterData.filter((x)=>x.mobile == this.state.custMobile)
              filterData=aar
      }
      if(this.state.companyName){
        let aar =  filterData.filter((x)=>x.companyNameSlug.includes(this.state.companyName.toLowerCase()))
              filterData=aar
      }
      if(this.state.selectedCountry){
        // let aar =  filterData.filter((x)=>x.country.includes(this.state.selectedCountry.toLowerCase()))
        let aar =  filterData.filter((x)=>x.country == this.state.selectedCountry)
              filterData=aar
      }
      if(this.state.selectedState){
        let aar =  filterData.filter((x)=>x.stateSlug.includes(this.state.selectedState.toLowerCase()))
              filterData=aar
      }
      if(this.state.selectedCity){
        let aar =  filterData.filter((x)=>x.citySlug.includes(this.state.selectedCity.toLowerCase()))
        filterData=aar
      }
      if(this.state.selectedCity1){
        let aar =  filterData.filter((x)=>x.citySlug.includes(this.state.selectedCity1.toLowerCase()))
        filterData=aar
      }
      console.log("filterData ::",filterData)
      this.setState({
        siteData: filterData,
        loading: false,
        filterLoadimg: false
      })
    }
    resetFilter=()=>{
      this.setState({
        planNames: [],
        siteStatus: undefined,
        partnerType: undefined,
        partnerName: undefined,
        salesPerson: undefined,
        supportAccM: undefined,
        paymentFilter: undefined,
        custName: undefined,
        custMobile: undefined,
        companyName: undefined,
        selectedCountry: undefined,
        selectedState: undefined,
        selectedCity: undefined,
        selectedCity1: undefined,
        allState: []

      },()=>{
        this.callPromise()
      })
    }
    partnerTypeChange=(value)=>{
      this.setState({
          partnerType: value,
          partnerName: undefined
      })
  }
  partnerNameChange=(value)=>{
    this.setState({
      partnerName: value
    })
}
  salesPersonChange=(value)=>{
    this.setState({
      salesPerson: value
    })
}
supportAccMChange=(value)=>{
    this.setState({
      supportAccM: value
    })
}
paymentFilterChange=(value)=>{
    this.setState({
      paymentFilter: value
    })
}
custNameChange=(e)=>{
    this.setState({
      custName: e.target.value
    })
}
custMobileChange=(e)=>{
    this.setState({
      custMobile: e.target.value
    })
}
companyNameChange=(e)=>{
    this.setState({
      companyName: e.target.value
    })
}
onCountryChange=async(value)=>{
      let index = country.findIndex((x)=>x.value == value)
   let response = await getStateByCountry(country[index].key)
   console.log("response ::",response)
   if(response){
   this.setState({
    selectedCountry: value,
    allState:response
   })
  }

}
onStateChange=async(value)=>{
  let cIndex = country.findIndex((x)=>x.value == this.state.selectedCountry)
  let sIndex = this.state.allState.findIndex((x)=>x.name === value)
  let cities = await getcitiesByStateAndCountry(country[cIndex].key,this.state.allState[sIndex].iso2)
  console.log("cities ::",cities)
  this.setState({
    selectedState: value,
    allCities: cities
  })
}
onCityChange=(value)=>{
    this.setState({
      selectedCity: value
    })
}
onCityChange1=(e)=>{
    this.setState({
      selectedCity1: e.target.value
    })
}
truncateString=(str, maxLength)=> {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  } else {
    return str;
  }
}
currencySym=(amount,curr)=>{
  if(amount){
  return amount.toLocaleString(`en-${
    !(curr) && curr == 'INR' ? 'IN' : curr == 'USD' ? 'US' : curr == 'ZMW' ? 'ZMW' : 'IN'
  
  }`, { style: 'currency', currency: curr ?  curr : "INR" })
  }
}
    
  render(){
    const { getFieldDecorator } = this.props.form;
    const { planData } = this.state;
    // console.log("siteData::",this.state.siteData)
    const userRole = this.props && this.props.userData && this.props.userData.userRole ? this.props.userData.userRole : null
  let bb=  this.state.currentB && this.state.currentB.balance ? this.state.currentB.balance/100 : "0.00"
  let balane =  bb.toLocaleString(`en-${this.state.modalData.curr == 'INR' ? 'IN' : this.state.modalData.curr == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: this.state.modalData.curr })
    const columns = [
    {
      title: 'Business Name',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 250,
      fixed: 'left',
      ...this.getColumnSearchProps('companyName'),
      render:(data)=>{
        return(
          <span>{this.truncateString(data,40)}</span>
        )
      }
    },
    {
      title:"Domain",
      // dataIndex: 'fqdn',
      key: 'fqdn',
      width: 210,
      ...this.getColumnSearchProps('fqdn'),
      render: (record) => {
        return(
          <span>{this.truncateString(record.fqdn,30)}</span>
        )
      }
  
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      width: 130,
    },
    {
      title: 'Customer Name',
      dataIndex: 'custName',
      key: 'custName',
      width: 190,
      ...this.getColumnSearchProps('custName'),
      render:(data)=>{
        return(
          <span>{this.truncateString(data,30)}</span>
        )
      }
    },
    {
      title: 'Customer Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 100,
      ...this.getColumnSearchProps('mobile'),
    },
    {
      title: 'Customer Email',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      width: 210,
      render:(data)=>{
        return(
          <span>{this.truncateString(data,30)}</span>
        )
      }
    },
    {
      title: 'Support Act Manager',
      dataIndex: 'supportAccManager',
      key: 'supportAccManager',
      width: 130,
    },
    {
      title: 'Sales Person',
      dataIndex: 'salesPerson',
      key: 'salesPerson',
      width: 130,
  },
  {
    title: 'Partner Type',
    dataIndex: 'partnerType',
    key: 'partnerType',
    width: 120,
  },
  {
    title: 'Partner Name',
    dataIndex: 'channelPartner',
    key: 'channelPartner',
    width: 140,
  },
  {
    title: 'Site Type',
    dataIndex: 'siteType',
    key: 'siteType',
    width: 110,
  },
  {
    title: 'Site Option',
    dataIndex: 'siteOptions',
    key: 'siteOptions',
    width: 170,
    render: (data)=>{
      return(
        <div>
       {data != null && <span>{data}</span>}
       {data == null && <span>--</span>}
       </div>
      )
    }
  },
  // {
  //   title: 'Reference Domain',
  //   // dataIndex: 'userName',
  //   // key: 'userName',
  //   width: 200,
  // },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 90,
    sorter: (a, b) => {
      return a.createdAt -  b.createdAt
    },
    render: (data)=>{
      return(
        <div>
       {data != null && 
       moment.unix(data).format("DD/MM/YYYY")
       }
       {data == null && <span>--</span>}
       </div>
      )
    }
  },
    
  {
    title: 'Last Login Date',
    dataIndex: 'lastAccessAt',
    key: 'lastAccessAt',
    width: 130,
    sorter: (a, b) => {
      return a.lastAccessAt -  b.lastAccessAt
    },
    render: (data)=>{
      return(
        <div>
       {data != null && 
       moment.unix(data).format("DD/MM/YYYY HH:MM")
       }
       {data == null && <span>--</span>}
       </div>
      )
    }
  },
  {
    title: 'License Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 90,
  },
  {
    title: 'License End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 90,
  },
    {
      title: 'Status',
      dataIndex: 'siteStatus',
      key: 'siteStatus',
      width: 70,
      render:(siteStatus)=>{
        return(
          <span>{siteStatus ? 'Active': 'Inactive'}</span>
        )
      }
    },
    {
      title: 'Sub Amt',
      // dataIndex: 'subscriptionPrice',
      key: 'subscriptionPrice',
      width: 60,
      render:(data)=>{
        return(
          <span>{returnSymbol(parseFloat(data.subscriptionPrice),data.basicSiteSetting && data.basicSiteSetting.curr ? data.basicSiteSetting.curr : 'INR' )}</span>
        )
      }
    },
     {
      title: 'Action',
      fixed: 'right',
      width: userRole =='ADMIN' ? 330 : 230,
      render:(data)=>{
        return (
          <span>
            <Tag  color="green"  style={{cursor:"pointer", marginRight: '5px' }} onClick={() => this.editClick(data)}>Edit</Tag>
            <Link to={{ pathname: "/service-typelist", state: { siteData: data } }}>
              <Tag color="lime" style={{cursor:"pointer", marginRight: '5px' }}>Menu</Tag>
            </Link>
            {/* <Tag  color="lime" style={{ cursor:"pointer",marginRight: '5px' }} onClick={() => this.modalChange(data)}>Wallet</Tag> */}
            <Tag  color="blue" style={{ cursor:"pointer",marginRight: '5px' }} onClick={() => this.paymentDetails(data)}>Payment</Tag>
            <Tag  color="magenta" style={{ cursor:"pointer",marginRight: '5px' }} onClick={() => this.siteLogs(data)}>History</Tag>
            {userRole == 'ADMIN' &&
              <>
                <Popconfirm title="Sure to reset?" onConfirm={() => this.siteReset(data)}>
                  <Tag color="red" style={{ cursor:"pointer",marginRight: '5px' }}>Reset</Tag>
                </Popconfirm>
                <Popconfirm title="Sure to delete?" onConfirm={() => this.siteDelete(data)}>
                  <Tag color="red" style={{cursor:"pointer", marginRight: '5px' }}>Delete</Tag>
                </Popconfirm>
              </>}
          </span>
        )
      }
    }
  ];

    // const antIcon = <Icon type="loading" style={{ fontSize: 34,textAlign: "center" }} spin />;
        return(
            <App header={"Manage Sites"}>
              {/* {this.state.show && <a id="down"  download='site-data.csv' href={`https://${bucket}.s3.amazonaws.com/site-status/${stage}/inactive.csv`}></a>}
              {this.state.show1 && <a id="down1"  download='site-data.csv' href={`https://${bucket}.s3.amazonaws.com/site-status/${stage}/all_sites.csv`}></a>} */}
              <Row gutter={24}>
                <Col md={{ span: 5 }}>
              <Select
                optionFilterProp="children"
                style={{ width: '100%',marginRight: '10px' }}
                defaultValue={this.state.siteType}
                onChange={this.handleSiteTypeChange}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Please select">
                {type.map((c) => {
                  return (
                    <Select.Option key={c.key} value={c.value} >{c.label}</Select.Option>
                  )
                })}
              </Select>
                </Col>
                <Col md={{ span: 5 }}>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: '100%',marginRight: '10px' }}
                onChange={this.handleSiteOption}
                value={this.state.siteOption}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Please select">
                {PLATFORM.map((c) => {
                  return (
                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                  )
                })}
              </Select>
              </Col>
              <Col md={{ span: 5 }}>
              <Select
                    showSearch
                    style={{ width: '100%',marginRight: '10px' }}
                    placeholder="Site Plan"
                    optionFilterProp="children"
                    onChange={this.onChangePlan}
                    value={this.state.planNames}
                    mode="multiple"
                    allowClear
                    // dropdownMatchSelectWidth={false}
                >
                 {planData && planData.length >0 ?planData.map((c) => {
                    return (
                        <Option key={c.planName} title={c.planName} value={c.planName}>
                          <div style={{ whiteSpace: 'normal' }}>{c.planName}</div>
                          </Option>
                    )})
                    : ''}  
                </Select>
                </Col>
                <Col md={{ span: 5 }}>
                <Select
                                            optionFilterProp="children"
                                            style={{ width: '100%',marginRight: '10px' }}
                                            onChange={this.handleSiteStatus}
                                            value={this.state.siteStatus}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            allowClear
                                            placeholder="Site Status">
                                                    <Select.Option key={true} value={true} >Active</Select.Option>
                                                    <Select.Option key={false} value={false} >Inactive</Select.Option>
                                        </Select>
                                        </Col>
                                        <Col md={{ span: 4 }}>
              <Button type='danger' style={{ float:"right", marginBottom: 16, cursor: "pointer",marginLeft: '10px', width: 60 }} onClick={this.resetFilter}>Reset</Button>

              <Button type='primary' style={{ float:"right", marginBottom: 16, cursor: "pointer",width: 70}} loading={this.state.filterLoadimg} onClick={this.handleFilter}>Search</Button>
              </Col>
                 {/* <Button type="primary" loading={this.state.donloadLoding} onClick={this.downloadInactive} style={{ float:"right", marginBottom: 16, cursor: "pointer", color:"#fff",marginRight: '20px' }}>
                    Download Inactive Sites
                 </Button>
                 <Button type="primary" loading={this.state.donloadAllLoding} onClick={this.downloadAllSites} style={{ float:"right", marginBottom: 16, cursor: "pointer", color:"#fff",marginRight: '20px' }}>
                    Download All Sites
                 </Button> */}
                </Row>
              <Row gutter={24}>
              <Col md={{ span: 5 }}>
              <Select
                                            optionFilterProp="children"
                                            style={{ width: '100%',marginRight: '10px' }}
                                            onChange={this.partnerTypeChange}
                                            value={this.state.partnerType}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            allowClear
                                            showSearch
                                            placeholder="Partner Type">
                                                    <Select.Option key={'cp'} value={'CP'} >CP</Select.Option>
                                                    <Select.Option key={'reseller'} value={'RESELLER'} >Reseller</Select.Option>
                                                    <Select.Option key={'referral'} value={'REFERRAL'} >Referral</Select.Option>
                                                    <Select.Option key={'stockist'} value={'STOCKIST'} >Stockist</Select.Option>
                                        </Select>
                                        </Col>
                                        <Col md={{ span: 5 }}>
                                        
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%',marginRight: '10px' }}
                  onChange={this.partnerNameChange}
                  value={this.state.partnerName}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  showSearch
                  placeholder="Partner Name">
                  {this.state.InternalUserData && this.state.InternalUserData.length > 0 ?
                    this.state.InternalUserData.map((ele) => {
                      if (ele.userRole == this.state.partnerType) { 
                      if(this.state.partnerType == 'CP' ){
                        if( ele.companyName){
                        return (
                          <Select.Option key={ele.companyName} value={ele.companyName} >{ele.companyName}</Select.Option>
                        )
                      }
                      }else{
                        return (
                          <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                        )}
                      }
                    }): ''}
                </Select>
                </Col>
                <Col md={{ span: 5 }}>
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%',marginRight: '10px' }}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.salesPersonChange}
                  value={this.state.salesPerson}
                  allowClear
                  showSearch
                  placeholder="Sales Person">
                  {this.state.InternalUserData && this.state.InternalUserData.length > 0 ?
                    this.state.InternalUserData.map((ele) => {
                      if (ele.userRole == 'SALES_PERSON') {
                        return (
                          <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                        )
                      }
                    }): ''}
                </Select>
                </Col>
                <Col md={{ span: 5 }}>
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%',marginRight: '10px' }}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.supportAccMChange}
                  value={this.state.supportAccM}
                  allowClear
                  showSearch
                  placeholder="Support Account Manager">
                  {this.state.InternalUserData && this.state.InternalUserData.length > 0 ?
                    this.state.InternalUserData.map((ele) => {
                      if (ele.userRole == 'SUPPORT_ACCOUNT_MANAGER') {
                        return (
                          <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                        )
                      }
                    }): ''}
                </Select>
                </Col>
                <Col md={{ span: 4 }} style={{textAlign:'right'}}>
              <span >{`Search Count ${this.state.siteData.length}`}</span>
                </Col>
              </Row>
              <Row gutter={24} style={{marginTop: '15px'}}>
              <Col md={{ span: 5 }}>
              <Select
                  optionFilterProp="children"
                  style={{ width: '100%',marginRight: '10px' }}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.paymentFilterChange}
                  value={this.state.paymentFilter}
                  allowClear
                  showSearch
                  placeholder="Payment Filter">
                          <Select.Option key={'EXPIRING_30DAYS'} value={'EXPIRING_30DAYS'}>Expiring in next 30 days</Select.Option>
                          <Select.Option disabled key={'CUSTOMER_LOW_BALANCE'} value={'CUSTOMER_LOW_BALANCE'}>Customers with -ve balance</Select.Option>
                          <Select.Option key={'FREE_SITE'} value={'FREE_SITE'}>Site subscription set to 0 </Select.Option>
                </Select>
                </Col>
                <Col md={{ span: 5 }}>
                <Input placeholder="Business Name" value={this.state.companyName} onChange={this.companyNameChange}   style={{ width: '100%',marginRight: '10px' }}/>
                </Col>
                <Col md={{ span: 5 }}>
                <Input placeholder="Customer Name" value={this.state.custName} onChange={this.custNameChange}   style={{ width: '100%',marginRight: '10px' }}/>
                </Col>
                <Col md={{ span: 5 }}>
                <Input placeholder="Customer Mobile" value={this.state.custMobile} onChange={this.custMobileChange} style={{ width: '100%',marginRight: '10px' }}/>
                </Col>
                <Col md={{ span: 4 }}>
                </Col>
              </Row>
              <Row gutter={24} style={{marginTop: '15px'}}>
              <Col md={{ span: 5 }}>
                <Select 
                optionFilterProp="children"
                style={{ width: '100%',marginRight: '10px' }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Country"
                value={this.state.selectedCountry}
                allowClear
                showSearch
                onChange={this.onCountryChange}>
                                            {country.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value} >{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                </Col>
                <Col md={{ span: 5 }}>
                <Select 
                optionFilterProp="children"
                style={{ width: '100%',marginRight: '10px' }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="State"
                value={this.state.selectedState}
                showSearch
                allowClear
                onChange={this.onStateChange}>
                                            {this.state.allState && this.state.allState.length >0 && this.state.allState.map((c) => {
                                                return (
                                                    <Option key={c.iso2} value={c.name} >{c.name}</Option>
                                                )
                                            })}
                                        </Select>
                </Col>
                <Col md={{span:5}}>
                {this.state.selectedState ? 
                <Select 
                optionFilterProp="children"
                style={{ width: '100%',marginRight: '10px' }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="City"
                value={this.state.selectedCity}
                showSearch
                allowClear
                onChange={this.onCityChange}>
                                            {this.state.allCities && this.state.allCities.length >0 && this.state.allCities.map((c) => {
                                                return (
                                                    <Option key={c.id} value={c.name} >{c.name}</Option>
                                                )
                                            })}
                                        </Select>
                                        :
                                            <Input onChange={this.onCityChange1}/>
                                          }
                  </Col>
                                        <Link  to={{pathname:"/create-site",state:{masterData: this.state.masterSite}}}> 
                 <Button type="primary" style={{ float:"right", marginBottom: 16, cursor: "pointer", color:"#fff" }}>
                     Create New Site
                 </Button>
                 </Link>
              </Row>
                <Row>
               
                </Row>
                 <div className='sitelist-table'>
                <Table
                    className='sitetable'
                    columns={columns}
                    bordered
                    dataSource={this.state.siteData}
                    loading={this.state.loading}
                    size="middle"
                    rowKey="id"
                    scroll={{ x: '110%' }}
                    pagination={{ pageSize: 100 }}
                />
                </div>
                <Modal
                    title={`Payment Details: ${this.state.siteDetails.fqdn}`}
                    visible={this.state.visiblePayment}
                    width={1300}
                    onCancel={this.handleCancelPayment}
                    footer={null}
                    destroyOnClose={true}
                  >
                    <PaymentDetails  siteData={this.state.siteDetails} userData={this.props.userData}/>
                  </Modal>
                
                <Modal
                    title="Wallet Recharge"
                    visible={this.state.visibleM}
               
                    onCancel={this.handleCancel}
                    footer={null}
                  >
                    <Row>
                    <span >Current Balance : <b>{balane}</b></span>
                    </Row>
                    <br/>
                    <Row>
                    <Form onSubmit={this.handleSubmit} style={{marginTop: '10px'}}>
                    
                    <FormItem
                                label="Recharge Amount"
                            >
                                {getFieldDecorator(`amount`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Recharge Amount between 100 To 100000",
                                        // min:3,max:6
                                    },
                                    ,
                                    {
                                      validator: (rule, value, cb) => {
                                          if (value <1) {

                                              // if (value <100) {
                                                  cb('Please enter Recharge Amount Grater than 100')
                                              // }
                                          }
                                          cb()
                                      }
                                  }
                                   ]
                                })(
                                  <Input   type= "number" placeholder="Enter Recharge Amount" style={{width:"50%"}} onChange={this.rAmount}></Input>
                                )}
                            </FormItem>
                            <FormItem label='Notes' >
                                    {getFieldDecorator('note', {
                                       
                                        rules: [{
                                            required: true,
                                            message: "note",
                                        }],
                                    })(
                                        <Input placeholder="Note" style={{ width: '50%' }} />
                                    )}
                                </FormItem>
                    </Form>
                    </Row>
                    <Row>
                      <Button type="primary" onClick={this.handleOk} style={{marginTop: "20px"}} loading={this.state.paymentLoading}>Submit</Button>
                    </Row>
                  </Modal>
                  <Modal
                    // title="History"
                    title={`History: ${this.state.siteDetails ? this.state.siteDetails.fqdn : ''}`}
                    visible={this.state.historyModal}
                    onCancel={this.handleCancelhistoryModal}
                    footer={null}
                    width={1200}
                    destroyOnClose={true}
                  >
                    <SiteLogs siteDetails={this.state.siteDetails} userData={this.props.userData}/>
                    </Modal>
                {/* </>
                } */}
            </App>
        )
  }
}

const WrappedSiteList = Form.create({ name: "Services_form" })(
  SiteList
);
// const WrapSiteList =  compose(
//   graphql(GetSiteList,{
//       options: props => ({ 
//           fetchPolicy:"network-only"
//       }),
//   })
 
// )(WrappedSiteList)
export default withApollo(WrappedSiteList)